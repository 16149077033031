import { action, createStore, persist } from "easy-peasy";

const store = createStore(
  persist(
    {
      itemMeasurements: [],
      setItemMeasurements: action((state, payload) => {
        state.itemMeasurements = payload;
      }),
      defaultCustomer: {},
      setDefaultCustomer: action((state, payload) => {
        state.defaultCustomer = payload;
      }),
    },
    {
      storage: "localStorage",
    }
  )
);

export default store;
