import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import currency from "currency.js";
import { useAuth } from "../../hooks/useAuth";

const options = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
];

export default function PermitModal({
  setShowPermitModal,
  handleSelectedPermit,
  batchData = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    Status: "",
    batchData,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getPermits = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/permits/all?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { permits: [] } } = useQuery(
    [queryActions.ALL_PERMITS, debouncedqueryParams],
    () => getPermits(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      show={true}
      onHide={() => setShowPermitModal(false)}
      dialogClassName=""
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Approval</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4">
          <div className="global-search-area  flex-grow-1">
            <MagnifyIcon />
            <Form.Control
              id="queryParams-q"
              className=""
              name="q"
              value={queryParams.q}
              onChange={(e) => handleSearchQueryChange(e)}
              placeholder="Search..."
            />
          </div>

          <Form.Group>
            <Select
              classNamePrefix="form-select"
              placeholder="Status"
              isSearchable={false}
              onChange={({ value }) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  Status: value,
                })
              }
              value={options.find((el) => el.value === queryParams.Status)}
              options={options}
            />
          </Form.Group>
        </div>

        <div className="content px-3 pb-4 pe-2">
          <Table borderless striped responsive className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th>Trans. ID</th>
                <th>Total Value</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data.permits &&
                data.permits.map((el, index) => (
                  <tr
                    key={index}
                    onClick={() => handleSelectedPermit(el)}
                    className={`p-cursor`}
                  >
                    <td>{el.TransactionID}</td>
                    <td>
                      {currency(el.totalValue, {
                        symbol: "",
                      }).format()}
                    </td>

                    <td>{el.Status}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
