import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ExchangeFunds,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";

function Expenses() {
  useScrollTop();
  const initialFilterParams = {
    header: "",
    description: "",
    branch: "",
  };

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const fetchExpenses = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/expenses?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Department,
        value: el.Department,
      })),
    ];

    return data;
  };

  const {
    data = { count: 0, expenses: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.EXPENSES, queryParams],
    () => fetchExpenses(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      name="branch"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.branch,
                        data?.branch,
                        { value: "", label: "All" }
                      )}
                      options={data?.branch || []}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Header</Form.Label>
                    <Form.Control
                      name="header"
                      placeholder="Enter Header"
                      value={filterParams?.header}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      name="description"
                      value={filterParams?.description}
                      placeholder="Enter Description"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <hr className="mt-3 mb-4" />

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Dapartment Expenses History
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>
              <div className="actions mr-5">
                {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn sendSms">
                  Update Expenses <ExchangeFunds />
                </button> */}
              </div>
            </header>

            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th>Exp Header</th>
                    <th>Requester Name</th>
                    <th>Department</th>
                    <th>Amount</th>
                    <th>Purpose</th>
                    <th>Exp Date</th>
                    <th>Exp ID</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  {data.expenses.map((el, index) => (
                    <tr key={index}>
                      <td>{el.Header}</td>
                      <td>{el.ReqName}</td>
                      <td>{el.Department}</td>
                      <td>
                        {currency(el.Amount, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>{el.Purpose}</td>
                      <td>
                        {el.ReqDate
                          ? format(new Date(el.ReqDate), "dd MMM yyyy")
                          : "..."}
                      </td>
                      <td>{el.ExpID}</td>
                      <td>{el.username}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.expenses) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            <div className="summaryNew ">
              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalExpenses, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Expenses</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Expenses;
