import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";

export default function ItemsTable({
  handleSelectedItem,
  hideItemsNotInStock = false,
}) {
  const { backendUrl } = useAuth();
  const [searchString, setSearchString] = useState("");
  const debouncedSearchString = useDebounce(searchString, 500);

  const getItems = async (q) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/items?&q=${q}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] }, isSuccess } = useQuery(
    [queryActions.GET_ITEMS, debouncedSearchString],
    () => getItems(debouncedSearchString),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Search item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">BarCode</th>
              <th scope="col">Item Name</th>
              <th scope="col">Unit Cost</th>
              <th scope="col">Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {data.items &&
              data.items
                .filter((el) =>
                  hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                )
                .map((el, index) => (
                  <tr
                    className="p-cursor"
                    key={index}
                    onClick={() => handleSelectedItem && handleSelectedItem(el)}
                  >
                    <td>{el.Bar_Code}</td>
                    <td title={el?.Quantity}>{el.Item_Name}</td>
                    <td>
                      {currency(el.UnitCost, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.UnitPrice, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
