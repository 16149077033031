import { Button, Form, Table, Overlay, Popover } from "react-bootstrap";
import DetailedIcon, {
  CalendarIcon,
  ExportIcon,
  FilePdfIcon,
  FilterCollapseIcon,
  FilterIcon,
  PrintIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect, useState } from "react";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import { useLocalStorage, useQueryParams } from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { reportActions } from "../../utils/reactQueryActions";
import { format, parse } from "date-fns";
import {
  customerFullName,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
} from "../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomInput from "./../utils/DatePickerCustomInput";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { isEmpty, isNull, map } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import NoTableItem from "../utils/NoTableItem";

function PurchaseHistory() {
  const reportTypeOptions = [
    {
      value: "Simple",
      label: "Simple Report",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    reportType: "Simple",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    searchInDate: true,
    invoiceNumber: "",
    modelNumber: "",
    vendorNumber: "",
  };

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const fetchPurchaseHistory = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/purchase-history?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];
    return data;
  };

  const {
    data = { count: 0, purchaseHistory: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.PURCHASE_HISTORY, queryParams],
    () => fetchPurchaseHistory(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowCustomerSelector(false);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,

      // format the date, before setting it in the url
      date: filterParams?.date
        ? format(new Date(filterParams?.date), "yyyy-MM-dd")
        : "",
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setTimeout(() => {
      setQueryParams({
        ...queryParams,
        ...initialFilterParams,
      });
    }, 0);
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Report Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      isSearchable={false}
                      theme={reactSelectTheme}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      name="branch"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.branch && data?.branch
                          ? data?.branch.find(
                              (el) => el.value === queryParams?.branch
                            )
                          : { value: "", label: "All" }
                      }
                      options={data?.branch}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Invoice No.</Form.Label>
                    <Form.Control
                      name="invoiceNumber"
                      defaultValue={queryParams?.invoiceNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Invoice No."
                    />
                  </Form.Group>

                  <hr className="my-3" />

                  <Form.Group className="mb-2-5">
                    <Form.Label>Model No.</Form.Label>
                    <Form.Control
                      name="modelNumber"
                      defaultValue={queryParams?.modelNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Model No."
                    />
                  </Form.Group>

                  <hr className="my-3" />

                  <Form.Group className="mb-2-5">
                    <Form.Label>Vendor.</Form.Label>
                    <Form.Control
                      name="vendorNumber"
                      defaultValue={queryParams?.vendorNumber}
                      onChange={(e) => handleFilterParamsChange(e)}
                      placeholder="Input Vendor No."
                    />
                  </Form.Group>

                  <hr className="my-3" />

                  {/*      <Form.Group className="mb-2-5">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <ReactDatePicker
                        selected={filterParams?.date}
                        onChange={(date) =>
                          setFilterParams({
                            ...filterParams,
                            date,
                          })
                        }
                        customInput={<DatePickerCustomInput />}
                        dateFormat="MMMM dd, yyyy"
                        showPopperArrow={false}
                        placeholderText="Select date"
                      />
                    </div>
                  </Form.Group> */}

                  <Form.Group className="mb-2-5">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} type="reset" variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Purchase History Spreadsheet
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>

              <div className="actions mr-5">
                {/* <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button> */}

                {/*  <button className="btn sendSms">
                  Open Ledger <FilePdfIcon />
                </button> */}

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtnWhite "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table
                responsive
                borderless
                striped
                className="product-table text-nowrap"
              >
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Invoice No</th>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Vendor</th>
                    <th>QTY Purchased</th>
                    <th>Unit Cost</th>
                    <th>Amount</th>
                    <th>Purchase ID</th>
                    <th>Purchase Date</th>
                    <th>Username</th>
                    <th>Vendor ID</th>

                    <th>Driver Name</th>
                    <th>Truck Number</th>
                    <th>Phone Number</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.purchaseHistory.map((el, index) => (
                    <tr key={index}>
                      <td>{data?.startIndex + index + 1}</td>
                      <td>{el.InvoiceNo}</td>
                      <td>{el.Bar_Code}</td>
                      <td>{el.ItemName}</td>
                      <td>{el.Vendor_ID}</td>
                      <td>
                        {isIronRod
                          ? qtyFormatToString(
                              qtyFormat(
                                el.Quantity,
                                el.item.Item_Desc,
                                itemMeasurements
                              )
                            )
                          : el.Quantity}
                      </td>
                      <td>{el.UnitPrice}</td>
                      <td>{el.AmountDue}</td>
                      <td>{el.PurchaseID}</td>
                      <td>
                        {el.PurchaseDate
                          ? format(new Date(el.PurchaseDate), "dd MMM yyyy")
                          : "..."}
                      </td>
                      <td>{el.Username}</td>
                      <td>{el.Vendor_ID}</td>
                      <td>{el?.DriverName}</td>
                      <td>{el?.DriverPhoneNumber}</td>
                      <td>{el?.TruckNumber}</td>
                      <td>
                        {" "}
                        {el.DeliveryDate
                          ? format(new Date(el.DeliveryDate), "dd MMM, yyyy")
                          : "..."}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.purchaseHistory) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            {!data?.paginationDisabled ? (
              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>

                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={data.count / queryParams.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            ) : (
              <div className="py-2" />
            )}

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.amountDue, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount Due</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.amountPaid, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount Paid</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrown">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.balance, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Balance</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PurchaseHistory;
