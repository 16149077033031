import { Dropdown, Form, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Link, useSearchParams } from "react-router-dom";
import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useEffectOnce, useQueryParams } from "../../utils/hooks";
import Select from "react-select";
import CachedIcon from "mdi-react/CachedIcon";
import NewCustomerModal from "../NewCustomerModal";
import { queryActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import queryString from "query-string";
import { services } from "../../config";
import { paginationOptions, searchParamsToObject } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";

export function CustomerList() {
  const [searchParams] = useSearchParams();
  const { backendUrl, token } = useAuth();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );

  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  // action form url query on render once
  useEffectOnce(() => {
    const queryParams = searchParamsToObject(searchParams.entries());
    if (queryParams?.action === "create") {
      setShowCreateNewCustomerModal(true);
      // delete queryParams.action;
      setQueryParams({ ...initialFilterParams });
    }
  });

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { customers: [] }, refetch } = useQuery(
    [queryActions.CUSTOMERS, debouncedFilterParams],
    () => getCustomer(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  return (
    <main className="bg-white">
      <header className="p-4">
        <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
          <div className="d-flex flex-wrap gap-3">
            <Button
              variant="primary"
              onClick={() => setShowCreateNewCustomerModal(true)}
            >
              + Create New
            </Button>

            <div className="d-flex flex-wrap gap-3">
              <div className="global-search-area">
                <MagnifyIcon />
                <Form.Control
                  id="queryParams-q"
                  className=""
                  name="LastName"
                  value={filterParams.LastName}
                  onChange={(e) => handleSearchQueryChange(e)}
                  placeholder="Search..."
                />
              </div>
              <Button
                onClick={() => refetch()}
                variant=""
                className="bg-light-blue border-0 text-primary"
              >
                <CachedIcon />
              </Button>
            </div>
          </div>

          <div
            className="d-flex flex-wrap gap-3"
            style={{ fontSize: "0.875rem" }}
          >
            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Type"
                name="category"
                isSearchable={true}
                key={data?.category}
                /*  onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} */
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Last Created"
                name="category"
                isSearchable={true}
                key={data?.category}
                /*  onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} */
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Branch"
                name="category"
                isSearchable={true}
                key={data?.category}
                /*  onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} */
                options={data?.category || []}
              />
            </Form.Group>
          </div>
        </div>
      </header>

      <div className="content px-4 pb-4">
        <Table borderless striped responsive className="product-table">
          <thead className="sticky border-bottom">
            <tr>
              <th>Cust ID</th>
              <th>Customer Name</th>
              <th>Transaction Type</th>
              <th>Branch</th>
              <th>Department</th>
            </tr>
          </thead>

          <tbody className="blue-hover">
            {data.customers &&
              data.customers.map((el, index) => (
                <tr key={index}>
                  <td>{el.Cust_ID}</td>
                  <td>{el.LastName}</td>
                  <td>{el.TransType}</td>
                  <td>{el.Branch}</td>
                  <td>{el.Dept}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
        {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
        <div className="pagination_left d-flex gap-3 align-items-center">
          <p className="m-0 p-0">Show</p>
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
        />
      )}
    </main>
  );
}
