import { Modal, Button, Table, Dropdown, InputGroup } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import {
  customerFullName,
  qtyFormat,
  styleHelper,
  qtyFormatToString,
} from "../../utils/helpers";
import useDebounce, { useCanGoBack } from "../../utils/hooks";
import {
  reportActions,
  requisitionActions,
} from "../../utils/reactQueryActions";
import currency from "currency.js";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { DropdownCheckIcon, DropdownCloseIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import eventBus from "../../utils/EventBus";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ConfirmDialog from "../ConfirmDialogue";

export default function ApproveInvoiceModal() {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const goBack = useCanGoBack();
  let { id } = useParams();
  const [total, setTotal] = useState({
    byDefault: 0,
    onApproval: 0,
  });
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [tableData, setTableData] = useState([]);

  const convertQuantity = (Quantity, Item_Desc) => {
    const qtyValue = qtyFormat(Quantity, Item_Desc, itemMeasurements).split(
      "-"
    );
    const tons = qtyValue[0];
    const pcs = qtyValue[1];

    return Number(tons) > 0 && Number(pcs) <= 0 ? tons : Quantity;
  };

  const fetchPermits = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/permits/detail/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    setTableData(data.permits);

    return data;
  };

  const { data, refetch } = useQuery(
    [reportActions.PERMITS, { id }],
    () => fetchPermits(),
    {
      keepPreviousData: false,
    }
  );

  const saveChanges = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/save-changes`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const saveChangesMutation = useMutation((payload) => saveChanges(payload), {
    onSuccess: ({ description }) => {
      toast.success("Changes saved");
    },
    onError: () => {
      toast.error("Unable to save");
    },
  });

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const updatePermit = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/permits/change-status/${id}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const updatePermitMutation = useMutation((payload) => updatePermit(payload), {
    onSuccess: ({ status, transactionId, barcode }) => {
      const index = tableData.findIndex((el) => el.Bar_Code === barcode);
      if (status === "Approved") {
        tableData[index].Status = status;
        setTableData([...tableData]);
      } else {
        const oldTableData = tableData.filter((el, i) => i !== index);
        setTableData([...oldTableData]);
      }

      toast.success(`Permit ${status}`);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const save = () => {
    saveChangesMutation.mutate({
      rows: [...tableData],
    });
  };

  const approve = async (el) => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitMutation.mutate({
        status: "Approved",
        transactionId: el.TransactionID,
        barcode: el.Bar_Code,
      });
    }
  };

  const disapprove = async (el) => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitMutation.mutate({
        status: "Disapproved",
        transactionId: el.TransactionID,
        barcode: el.Bar_Code,
      });
    }
  };

  const updateAllPermit = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updateAllPermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        if (status === "Approved") {
          const newTableData = tableData.map((el, index) => ({
            ...el,
            Status: "Approved",
          }));
          setTableData([...newTableData]);
        } else {
          setTableData([]);
        }
        toast.success(`Success`);
        setTimeout(() => {
          eventBus.dispatch("FETCH_PENDING_PERMITS", null);
          navigate("/approval");
        }, 0);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const approveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Approve All",
        description: "Are you sure, you want to approve",
      })
    ) {
      updateAllPermitsMutation.mutate({
        status: "Approved",
        transactionIds: tableData.map((el) => el.TransactionID),
      });
    }
  };
  const disapproveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove All",
        description: "Are you sure, you want to disapprove all",
      })
    ) {
      updateAllPermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: tableData.map((el) => el.TransactionID),
      });
    }
  };

  const calculateTotal = (tableData) => {
    setTotal({
      byDefault: tableData
        .map((el) =>
          currency(el.Unit_Price, {
            symbol: "",
          }).multiply(convertQuantity(el.QTY, el.Serial_Number))
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        ),
      onApproval: tableData
        .map((el) =>
          currency(el.PriceSold, {
            symbol: "",
          }).multiply(convertQuantity(el.QTY, el.Serial_Number))
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        ),
    });
  };

  const debouncedTableData = useDebounce(tableData, 500);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  return (
    <Modal
      show={true}
      onHide={() => goBack("/approval")}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      fullscreen={styleHelper.isMobile}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Sales Cost Approval Request/Detail</h1>
          <p>More information about the request sent</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive borderless striped className="product-table">
          <thead>
            <tr>
              <th>Barcode</th>
              <th>Item Name</th>
              <th>Unit Price</th>
              <th>Price Sold</th>
              <th>Quantity</th>
              <th>Unit Cost</th>
              <th>Default Sub</th>
              <th>New Sub To</th>
              <th>Customer </th>
              <th>Sent By</th>
              <th>Status</th>
              <th> {"  "}</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((el, index) => (
              <tr
                key={index}
                className={`${activeTableIndex === index && "active-row"}`}
                onClick={() =>
                  activeTableIndex !== index && setActiveTableIndex(index)
                }
              >
                <td>{el.Bar_Code}</td>
                <td>{el.Item_Name}</td>
                <td>
                  {currency(el.Unit_Price, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  <CurrencyInput
                    className="form-control border-0 px-1"
                    value={el.PriceSold}
                    onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                    name="PriceSold"
                    onValueChange={(value, name) =>
                      handleInputChange({
                        index,
                        name,
                        value,
                      })
                    }
                    disableGroupSeparators
                    allowNegativeValue={false}
                    allowDecimals={true}
                  />
                </td>
                <td>
                  <span className="text-nowrap">
                    {qtyFormatToString(
                      qtyFormat(el.QTY, el.Serial_Number, itemMeasurements)
                    )}
                  </span>
                </td>
                <td>
                  {currency(el.UnitCost, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el.Unit_Price, {
                    symbol: "",
                  })
                    .multiply(convertQuantity(el.QTY, el.Serial_Number))
                    .format()}
                </td>
                <td>
                  {currency(el.PriceSold, {
                    symbol: "",
                  })
                    .multiply(convertQuantity(el.QTY, el.Serial_Number))
                    .format()}
                </td>
                <td>{customerFullName(el.customer)}</td>
                <td>{el.UserName}</td>
                <td>{el.Status}</td>
                <td>
                  <div className="d-flex gap-3">
                    <Button
                      size="sm"
                      className="d-flex"
                      onClick={() => approve(el)}
                    >
                      Approve
                    </Button>
                    <Button
                      size="sm"
                      className="d-flex"
                      variant="outline-danger"
                      onClick={() => disapprove(el)}
                    >
                      Disapprove
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="d-flex justify-content-between pb-4 mt-3 mb-4 border-bottom d-print-none">
          {![null, -1].includes(activeTableIndex) ? (
            <Button
              onClick={() => removeSelection()}
              variant="danger"
              size="sm"
              className="text-white px-3 "
            >
              Remove Selection
            </Button>
          ) : (
            <span />
          )}

          <div className="proceed-actions">
            <Button
              disabled={saveChangesMutation.isLoading}
              onClick={() => save()}
              variant="outline-primary"
              size="sm"
            >
              {saveChangesMutation.isLoading ? "Please wait..." : "Save"}
            </Button>
          </div>
        </div>

        <div className="my-3 d-flex gap-3 justify-content-end">
          <div>
            <p>Total (By Default) </p>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">₦</InputGroup.Text>
              <CurrencyInput
                placeholder="0.00"
                className="form-control"
                //  disableGroupSeparators
                disabled
                value={total.byDefault}
              />
            </InputGroup>
          </div>

          <div>
            <p>Total (On Approval) </p>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">₦</InputGroup.Text>
              <CurrencyInput
                placeholder="0.00"
                className="form-control"
                //  disableGroupSeparators
                disabled
                value={total.onApproval}
              />
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => disapproveAll()}
          variant="danger"
          className="text-white px-3"
        >
          Disapprove All
        </Button>

        <Button onClick={() => approveAll()}>Approve all</Button>
      </Modal.Footer>
    </Modal>
  );
}
