import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";

function AccountsReceivables() {
  useScrollTop();
  const reportTypeOptions = [
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    customerLastname: "",
    customerFirstname: "",
  };

  const { backendUrl } = useAuth();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchRecievables = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/accounts-recievables?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.manufacturers = data.manufacturers.map((el) => {
      const value = !["None"].includes(el?.InvoiceCat) ? el.InvoiceCat : "";
      return {
        label: el?.InvoiceCat,
        value,
      };
    });
    return data;
  };

  const {
    data = { count: 0, recievables: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.ACCOUNT_RECIEVABLES, queryParams],
    () => fetchRecievables(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerLastname: customer.LastName,
      customerFirstname: customer.FirstName,
    });
    setShowItemsPopover(false);
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  {/*  <Form.Group className="mb-2-5">
                    <Form.Label>Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="All"
                      isSearchable={false}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group> */}
                  <Form.Group className="mb-2-5">
                    <Form.Label>Manufacturer</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      name="manufacturer"
                      placeholder="None"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.manufacturers}
                      options={data?.manufacturers || []}
                      defaultValue={
                        queryParams?.manufacturer && data?.manufacturers
                          ? data?.manufacturers.find(
                              (el) => el.value === queryParams?.manufacturer
                            )
                          : { value: "", label: "None" }
                      }
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          manufacturer: selected.value,
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer Lastname</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="customerLastname"
                        placeholder="Enter Customer Lastname"
                        value={filterParams?.customerLastname}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <CustomerSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer First Name</Form.Label>
                    <Form.Control
                      name="customerFirstName"
                      value={filterParams?.customerFirstname}
                      placeholder="Enter Customer firstname"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <hr className="mt-3 mb-4" />

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        disabled
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Receivables Spreadsheet
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>

              <div className="actions mr-5">
                {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn sendSms">
                  Send SMS <MessageIcon />
                </button> */}

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtnWhite "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              {queryParams?.manufacturer ? (
                <>
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Manufaturer</th>
                        <th>Total Debt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.debtorsCat?.map((el, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{el.InvoiceCat}</td>
                          <td>
                            {currency(el.TotalDebt, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!isFetching && isSuccess && isEmpty(data?.debtorsCat) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </>
              ) : (
                <>
                  <Table
                    responsive
                    borderless
                    striped
                    hover
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Cust ID</th>
                        <th>Customer</th>
                        <th>Phone No</th>
                        <th>Total Debt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.recievables.map((el, index) => (
                        <tr
                          className="p-cursor"
                          onClick={() =>
                            navigate(
                              `/reports/customer-ledger?customerId=${
                                el.Cust_ID
                              }&customerName=${
                                el.Customer
                              }&enableDateRange=false`
                            )
                          }
                          key={index}
                        >
                          <td>{data?.startIndex + index + 1}</td>
                          <td>{el.Cust_ID}</td>
                          <td>{el.Customer}</td>
                          <td>{el.PhoneNo1}</td>
                          <td>
                            {currency(el.TotalDebt, {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!isFetching && isSuccess && isEmpty(data.recievables) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </>
              )}
            </div>

            {!data?.paginationDisabled ? (
              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>

                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={data.count / queryParams.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            ) : (
              <div className="py-2" />
            )}

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.totalDebt, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Debt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">{0} </p>
                    <p className="gridChld2">Credit Count</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AccountsReceivables;
