import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";

function SelectBank({ proceed, show, hasChequeNumber, selectedBank }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const [banks, setBanks] = useState([]);

  const initialValues = {
    bank: selectedBank,
    ...(hasChequeNumber ? { chequeNumber: "" } : {}),
  };
  const schema = yup.object().shape({
    bank: yup.string().required(),
    /*  ...(hasChequeNumber
      ? { chequeNumber: yup.string().required("required") }
      : {}), */
  });

  const getBanks = async () => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/users/banks`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        toast.error(response.message);
      } else {
        const {
          data: { banks = [] },
        } = await response.json();
        setBanks(
          banks.map((el) => ({
            label: el.BankName,
            value: el.BankName,
          }))
        );
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to get banks");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      proceed(values);
    },
  });

  const canClose = () => {
    if (!isLoading) {
      if (!formik.values.bank) {
        formik.setFieldTouched("bank", true);
        formik.setFieldError("bank", "Please select a bank");
      } else {
        proceed(formik.values);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Select Bank</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Bank</Form.Label>
            <Select
              classNamePrefix="form-select"
              placeholder="Select"
              disabled={isLoading}
              isSearchable={false}
              options={banks}
              value={banks.find((el) => el.value === formik.values.bank)}
              onChange={({ value }) => formik.setFieldValue("bank", value)}
              onBlur={() => formik.setFieldTouched("bank", true)}
              className={
                formik.touched.bank && !!formik.errors.bank ? "is-invalid" : ""
              }
            />
            {formik.touched.bank && formik.errors.bank ? (
              <span className="custom-invalid-feedback">
                {formik.errors.bank}
              </span>
            ) : null}
          </Form.Group>

          {hasChequeNumber && (
            <Form.Group className="mb-3">
              <Form.Label>Cheque No</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Cheque Number"
                name="chequeNumber"
                value={formik.values.chequeNumber}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.chequeNumber && !!formik.errors.chequeNumber
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.chequeNumber}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function SelectBankDialog(props) {
  return createConfirmation(confirmable(SelectBank))({ ...props });
}
