import { Table } from "react-bootstrap";

import React, { Component } from "react";
import Select from "react-select";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

export function Test() {
  return (
    <div className="py-3">
      <div className="table-actions">
        <Select
          options={options}
          isSearchable={false}
          className="global-select"
          classNamePrefix="ga-select"
        />
      </div>
      <div className="bg-white p-3">
        <div className="p-3">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure,
            aperiam perferendis. Eligendi doloribus quisquam recusandae neque
            praesentium alias maiores consequuntur quaerat tempore odit! Nisi,
            obcaecati! Saepe porro nulla repellendus nihil.
          </p>

          {/*  <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />

          <CurrencyInput
            className="form-control"
            name="input-name"
            placeholder="Please enter a number"
            defaultValue={1000}
            decimalsLimit={2}
            onValueChange={(value, name) => console.log(value, name)}
          /> */}

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label className="form-check-label" for="flexCheckDefault">
              Default checkbox
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              checked
            />
            <label className="form-check-label" for="flexCheckChecked">
              Checked checkbox
            </label>
          </div>
        </div>
        <Table responsive striped borderless className="product-table">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Customer</th>
              <th>Cust ID</th>
              <th>Phone Number</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              {Array.from({ length: 4 }).map((_, index) => (
                <td key={index}>Table cell {index}</td>
              ))}
            </tr>
            <tr>
              <td>02</td>
              {Array.from({ length: 4 }).map((_, index) => (
                <td key={index}>Table cell {index}</td>
              ))}
            </tr>
            <tr>
              <td>03</td>
              {Array.from({ length: 4 }).map((_, index) => (
                <td key={index}>Table cell {index}</td>
              ))}
            </tr>
            <tr>
              <td>03</td>
              {Array.from({ length: 4 }).map((_, index) => (
                <td key={index}>Table cell {index}</td>
              ))}
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
}
