import { Table, Pagination } from "react-bootstrap";
import {
  PrintIcon,
  SummaryCloseIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  ExportIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon } from "../Icons";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useEffect, useState } from "react";

function VendorsPaymentHistory() {
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const removeSummaryOnScroll = () => {
    if (document.getElementsByClassName("open")) {
      if (window.scrollY) {
        setBtnSummaryActive(false);
      }
    }
  };

  useEffect(() => {
    removeSummaryOnScroll();
    window.addEventListener("scroll", removeSummaryOnScroll);

    return () => window.removeEventListener("scroll", removeSummaryOnScroll);
  }, []);

  return (
    <main className="cash-book">
      <div className="content">
        <header>
          <h1>
            <button className="btn filter">
              <FilterTwoIcon />
            </button>
            Payment Analysis
            <button className="btn text-primary">
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5">
            <form action="align-items-center">
              <MagnifyIcon className="searchIcon" />
              <input
                type="text"
                name="search"
                placeholder="Search item ID or item name..."
              />
            </form>

            <button className="btn print">
              Print <PrintIcon />
            </button>

            <button className="btn print">
              Export <ExportIcon />
            </button>

            <button
              onClick={() => setBtnSummaryActive(true)}
              className="btn summaryBtnWhite btn-outline-primary "
            >
              Show Summary
            </button>
          </div>
        </header>

        <Table responsive borderless striped className="product-table">
          <thead>
            <tr>
              <th>item ID</th>
              <th>Description</th>
              <th>QTY Received</th>
              <th>QTY Sold</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 10 }).map((_, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt...
                </td>
                <td>Table cell {index + 2}</td>
                <td>Table cell {index + 3}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="pagination">
          {/* Left */}
          <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

          {/* right */}
          <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div>
        </div>

        {/* <div className="p-4 border-top">
            <h2>Summary</h2>
            <div className="summary">
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Cash Sales</td>
                    <td>5,800.00</td>
                </tr>

                <tr>
                    <td>Total Cash PMT</td>
                    <td>350,250,895.00</td>
                </tr>

                <tr>
                    <td>Total Credit/Debit Card/Cheque</td>
                    <td>1,204,449,629.06</td>
                </tr>

                <tr>
                    <td>Total Cash</td>
                    <td>350,256,695.00</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Credit Sales</td>
                    <td>1,688,912,055.73</td>
                </tr>

                <tr>
                    <td>Total Posted</td>
                    <td>3,243,668,379.79</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Expenses</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>POS to Bank</td>
                    <td>103,366,800.00</td>
                </tr>

                <tr>
                    <td>Direct Bank Transfer</td>
                    <td>1,091,328,596.19</td>
                </tr>

                <tr>
                    <td>Cash Sent to Bank</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>Cash at Hand</td>
                    <td>337,732,030.10</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div> */}

        <div
          className={
            btnSummryActive
              ? `summaryNew border-top open`
              : `summaryNew border-top`
          }
        >
          <div className="summaryHeader">
            <h2>Summary</h2>
            {btnSummryActive && (
              <button onClick={() => close()} className="btn p-0">
                <SummaryCloseIcon />
              </button>
            )}
          </div>

          <div className="gridCont">
            <div className="gridChild gridChildBorderLeftBlue">
              <div className="gridChildLeft">
                <p className="gridChld1">5,800.00</p>
                <p className="gridChld2">Total Cash Sales</p>
              </div>

              <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default VendorsPaymentHistory;
