import { Fragment, useState, useEffect } from "react";
import "../assets/scss/NavBar.scss";
import { HamburgerIcon, HomeIcon, Logo } from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import photoUrl from "./../assets/images/profile.png";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  backendApis,
  reportsNavItems,
  salesUserReportNavItems,
  storeUserReportNavItems,
} from "../config";
import Avatar from "./utils/Avatar";
import {
  useIsAdmin,
  useIsCashier,
  useOnClickOutside,
  useToggle,
} from "../utils/hooks";
import { useRef } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import UpdateProfileModal from "./UpdateProfileModal";
import { isEmpty } from "lodash";
import { lowerCase } from "lodash";

export default function NavBar() {
  const location = useLocation();
  const {
    login,
    logout,
    setUser,
    backendUrl,
    user: authUser,
    isCement,
    isTransportAndLogistics,
    isIronRod,
  } = useAuth();
  const [smShow, setSmShow] = useState(false);
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const navHolder = useRef();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // whether to switch company
  const switchCompanyQueryParam = searchParams.get("switchCompany");
  useEffect(() => {
    if (
      switchCompanyQueryParam &&
      authUser.company !== switchCompanyQueryParam
    ) {
      const foundCompany = backendApis.find(
        (el) => el.name === switchCompanyQueryParam
      );
      console.log("foundCompany", foundCompany);
      if (foundCompany) switchCompany(foundCompany);
    }
  }, [switchCompanyQueryParam]);

  const logoutUser = async () => {
    try {
      setSmShow(true);
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({}),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSmShow(false);
    }
  };

  const handleKeyDown = (event) => {
    // event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    /* if ((event.ctrlKey || event.metaKey) && charCode === "l") {
      alert("CTRL+S Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      alert("CTRL+C Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      alert("CTRL+V Pressed");
    } */

    if (event.ctrlKey || event.metaKey) {
      switch (charCode) {
        case "l":
          logoutUser();
          break;
        default:
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const storeUserNav = useMemo(
    () => [
      {
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Return Invoice Item",
            to: "sales-and-invoicing/return-invoice-item",
          },
          {
            name: "Create Proforma Invoice",
            to: "sales-and-invoicing/create-proforma-invoice",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...storeUserReportNavItems],
      },
      {
        name: "View",
        childRoutes: [],
      },
      {
        name: "Shipping",
        childRoutes: [],
      },
      {
        name: "Vendor Management",
        childRoutes: [],
      },
      {
        name: "Request and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },
    ],
    [authUser?.company]
  );

  const salesUserNav = useMemo(
    () => [
      {
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Post Transaction",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Create Proforma Invoice",
            to: "sales-and-invoicing/create-proforma-invoice",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...salesUserReportNavItems],
      },
      {
        name: "Request and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },
    ],
    [authUser?.company]
  );

  const items = useMemo(
    () => [
      {
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Return Invoice Item",
            to: "sales-and-invoicing/return-invoice-item",
          },
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
          {
            name: "Create Proforma Invoice",
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: "Credit/Debit Memo",
            to: "credit-Debit-memo",
          },
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },

          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Sales",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Inventory For Sales",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          /*  {
            name: "Warehouse Inventory",
            to: "warehouse-inventory/inventory-list",
          }, */

          {
            name: "Supply Center",
            to: "supply-center",
          },
          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                    ]
                  : []),
                /* {
                  name: "Incentives",
                  to: "incentives",
                }, */
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry",
          },
        ],
      },
      {
        name: "Maintenance",
        childRoutes: [],
      },
      {
        name: "Banking",
        childRoutes: [],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      isAdmin
        ? {
            name: "InveX Settings",
            childRoutes: [],
          }
        : {},
      {
        name: "Warehouse",
        childRoutes: [],
      },
      {
        name: "Vendor Management",
        childRoutes: [],
      },
      {
        name: "Request and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },

      /* {
        name: <DotsHorizontalIcon style={{ color: "#94A3B8" }} />,
      }, */
    ],
    [authUser?.company]
  );

  const resolveNav = (department) => {
    department = lowerCase(department);
    return department === "store"
      ? storeUserNav
      : department === "sales"
      ? salesUserNav
      : items;
  };

  const switchCompany = async (company) => {
    console.log(company);
    try {
      setSmShow(true);
      // logout old company
      // ---------------------------------------------------------------------
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({}),
        credentials: "include",
      });

      if (!res.ok) throw new Error();

      // login new company
      //-------------------------------------------------------------------------
      const loginResponse = await fetch(`${company.url}/api/auth/authorize`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ username: authUser.username }),
        credentials: "include",
      });
      if (!loginResponse.ok) {
        logout();
      }

      const { user } = await loginResponse.json();
      if (isEmpty(user)) {
        toast.error("User not found in company: Try again");
        logout();
      }
      setUser({ ...user, company: company.name });
    } catch (err) {
      console.log(err);
      toast.error("Unable switch company, Please try again");
    } finally {
      setSmShow(false);
    }
  };

  return (
    <nav className="NavBar">
      <div className="d-flex align-items-center">
        <NavLink to={"/dashboard"} className="d-flex align-items-center">
          <div className="home-icon d-none">
            <HomeIcon />
          </div>
          <Logo className="logo" />
        </NavLink>
        <ul ref={navHolder} className={navOpen ? "open" : "closed"}>
          {resolveNav(authUser.Department).map((item, index) => (
            <li key={index}>
              {!item.childRoutes ? (
                <Link
                  to={item?.to ? item.to : ""}
                  className={` ${
                    location.pathname.startsWith(`/${item.to}`)
                      ? "active fw-6"
                      : ""
                  }`}
                >
                  {item.name}
                </Link>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    bsPrefix={`toggle ${
                      location.pathname.startsWith(`/${item.to}`)
                        ? "active fw-6"
                        : ""
                    }`}
                  >
                    {item.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {item.childRoutes.map((el, index) => (
                      <Fragment key={index}>
                        {el.to ? (
                          <Dropdown.Item as="div">
                            <Link
                              to={`${item.to ? `/${item.to}` : ""}/${el.to}`}
                            >
                              {el.name}
                            </Link>
                          </Dropdown.Item>
                        ) : el.onClick ? (
                          <Dropdown.Item
                            onClick={() => el.onClick()}
                            as="button"
                          >
                            {el.name}{" "}
                            <span className="shortcut">{el?.shortcut}</span>
                          </Dropdown.Item>
                        ) : null}
                      </Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </li>
          ))}
          {isAdmin || isCashier ? (
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="white" bsPrefix={`toggle`}>
                  Switch Company
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  {backendApis.map((el) => (
                    <Dropdown.Item
                      key={el.name}
                      as="button"
                      className={`${
                        authUser?.company === el.name ? "active" : ""
                      }`}
                      onClick={() => switchCompany(el)}
                    >
                      {el.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null}
        </ul>
      </div>

      <div className="d-flex">
        {/*   <ChevronDownIcon style={{ color: "#0A4A95" }} /> */}

        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            className="btn d-flex border-0 align-items-center p-1"
          >
            <Avatar
              className="avatar"
              src={photoUrl}
              name={authUser?.Name}
              company={authUser?.company}
              department={authUser?.Department}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
          >
            {/*  <Dropdown.Item as="button" onClick={() => logoutUser()}>
              Update Profile
            </Dropdown.Item> */}
            <Dropdown.Item as="button" onClick={() => logoutUser()}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button onClick={() => toggleNavOpen()} className="btn p-1 d-md-none">
          <HamburgerIcon />
        </button>
      </div>

      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        centered
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">...</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="text-center my-3 d-flex align-items-center justify-content-center gap-2">
          {/*  <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner> */}
          Please wait, processing request...
        </Modal.Body>
      </Modal>

      {/*  <UpdateProfileModal /> */}
    </nav>
  );
}
