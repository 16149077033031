import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveBlueModalIcon } from "./Icons";

function Dialog({ proceed, show, title = "", description = "" }) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info">
          <ApproveBlueModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p className="fs-6">{description}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions">
          <Button
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            No
          </Button>
          <Button onClick={() => proceed(true)} variant="primary">
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function ConfirmDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
