import { Button, Form, Table, Overlay, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  CalendarIcon,
  ExportIcon,
  FilterCollapseIcon,
  FilterIcon,
  PrintIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import { useEffect, useState } from "react";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { reportActions } from "../../utils/reactQueryActions";
import { format, parse } from "date-fns";
import {
  customerFullName,
  employeeFullName,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
} from "../../utils/helpers";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import DatePickerCustomInput from "./../utils/DatePickerCustomInput";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { isEmpty, isNull } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ItemsTable from "../utils/ItemsTable";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import NoTableItem from "../utils/NoTableItem";

export default function SalesAnalysis() {
  useScrollTop();

  const reportTypeOptions = [
    {
      value: "Detailed",
      label: "Detailed",
    },
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Full",
      label: "Full",
    },
  ];
  const initialFilterParams = {
    reportType: "Detailed",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    customerId: "",
    //  searchInDate: true,
  };

  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [showCustomerSelector, setShowCustomerSelector] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,

    // Get the date in url and parse so date picker can use it
    date: queryParams?.date
      ? parse(queryParams?.date, "yyyy-MM-dd", new Date())
      : "",
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchSales = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/sales-analysis?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.items = data.items.map((el) => ({
      label: el.Item_Name,
      value: el.Item_Name,
    }));

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch
        .map((el) => ({
          label: el.Branch,
          value: el.Branch,
        }))
        .filter((el) => Boolean(el.value)),
    ];

    data.salesRep = data.salesRep
      .map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      }))
      .filter((el) => el.label);

    data.overwriteOfficer = data.overwriteOfficer.map((el) => ({
      label: el.OverwriteOfficer,
      value: el.OverwriteOfficer,
    }));

    data.type = data.type.map((el) => ({
      label: el.Item_Type,
      value: el.Item_Type,
    }));

    console.log(data);
    return data;
  };

  const {
    data = { count: 0, sales: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.GET_SALES, queryParams],
    () => fetchSales(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      // ...searchParamsToObject(searchQuery.entries()),
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowCustomerSelector(false);
    // search({ customerId: customer.Cust_ID });
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
    });
  };

  const clearSelectedCustomer = () => {
    // search({ customerId: "" });
    setSelectedCustomer(null);
    setFilterParams({
      ...filterParams,
      customerId: "",
    });
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = (params) => {
    setQueryParams({
      ...queryParams,
      ...filterParams,

      // format the date, before setting it in the url
      date: filterParams?.date
        ? format(new Date(filterParams?.date), "yyyy-MM-dd")
        : "",
      ...params,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Branch</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      name="branch"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.branch}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          branch: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.branch && data?.branch
                          ? data?.branch.find(
                              (el) => el.value === queryParams?.branch
                            )
                          : { value: "", label: "All" }
                      }
                      options={data?.branch}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Sales Rep</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      name="salesRep"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.salesRep}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          salesRep: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.salesRep && data?.salesRep
                          ? data?.salesRep.find(
                              (el) => el.value === queryParams?.salesRep
                            )
                          : { value: "", label: "All" }
                      }
                      options={data?.salesRep}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Sales Type</Form.Label>
                    <Select
                      name="salesType"
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      isSearchable={false}
                      key={data?.overwriteOfficer}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          salesType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.salesType && data?.overwriteOfficer
                          ? data?.overwriteOfficer.find(
                              (el) => el.value === queryParams?.salesType
                            )
                          : { value: "", label: "All" }
                      }
                      options={data?.overwriteOfficer || []}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Report Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          reportType: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.reportType
                          ? reportTypeOptions.find(
                              (el) => el.value === queryParams?.reportType
                            )
                          : reportTypeOptions[0]
                      }
                      options={reportTypeOptions}
                    />
                  </Form.Group>
                  <hr />
                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      options={[
                        { value: "All Customers", label: "All Customers" },
                        {
                          value: "Selected Customer",
                          label: "Selected Customer",
                        },
                      ]}
                      onChange={(selected) =>
                        selected.value === "Selected Customer"
                          ? setShowCustomerSelector(true)
                          : clearSelectedCustomer()
                      }
                    />
                    <CustomerSelectorPopover
                      showCustomerSelector={showCustomerSelector}
                      setShowCustomerSelector={setShowCustomerSelector}
                      handleSelectedCustomer={handleSelectedCustomer}
                      selectedCustomer={selectedCustomer}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      name="type"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={true}
                      key={data?.type}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          type: selected.value,
                        });
                      }}
                      defaultValue={
                        queryParams?.type && data?.type
                          ? data?.type.find(
                              (el) => el.value === queryParams?.type
                            )
                          : { value: "", label: "All" }
                      }
                      options={data?.type}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Barcode</Form.Label>

                    <InputGroup>
                      <Form.Control
                        name="barcode"
                        onChange={(e) => handleFilterParamsChange(e)}
                        defaultValue={queryParams.barcode}
                        value={filterParams.barcode}
                        placeholder="Input Barcode"
                      />

                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <ItemsTable handleSelectedItem={handleSelectedItem} />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Item Name</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder=""
                      theme={reactSelectTheme}
                      isSearchable={true}
                      isClearable={true}
                      backspaceRemovesValue={true}
                      key={data?.items}
                      onChange={(selected, option) => {
                        setFilterParams({
                          ...filterParams,
                          itemName:
                            option.action !== "clear" ? selected.value : "",
                        });
                      }}
                      defaultValue={
                        queryParams?.itemName && data?.items
                          ? data?.items.find(
                              (el) => el.value === queryParams?.itemName
                            )
                          : {}
                      }
                      value={
                        data?.items
                          ? data?.items.find(
                              (el) => el.value === filterParams?.itemName
                            )
                          : {}
                      }
                      options={data?.items}
                    />
                  </Form.Group>

                  <hr />

                  {/*   <Form.Group className="mb-2-5">
                    <Form.Label>Date</Form.Label>
                    <div>
                      <ReactDatePicker
                        selected={filterParams?.date}
                        onChange={(date) =>
                          setFilterParams({
                            ...filterParams,
                            date,
                          })
                        }
                        customInput={<DatePickerCustomInput />}
                        dateFormat="MMMM dd, yyyy"
                        showPopperArrow={false}
                        placeholderText="Select date"
                      />
                    </div>
                  </Form.Group>

                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Search in Date"
                      name="searchInDate"
                      type={"checkbox"}
                      id={`searchInDate`}
                      defaultChecked={JSON.parse(
                        filterParams?.searchInDate || true
                      )}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div> */}

                  <Form.Group className="mb-2-5">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1 className="d-flex align-items-center gap-3">
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Sales/Cash Spreadsheet
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
                {selectedCustomer && (
                  <>
                    Customer Name: <b>{customerFullName(selectedCustomer)}</b>
                  </>
                )}
              </h1>

              <div className="actions">
                {/* <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button> */}
                <button
                  onClick={() => setBtnSummaryActive(!btnSummryActive)}
                  className="btn summaryBtn "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table
                responsive
                borderless
                striped
                className="product-table text-nowrap"
              >
                {queryParams.reportType === "Summary" ? (
                  <>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>{isCement ? "ATC" : "Item Code"}</th>
                        <th>Item Name</th>
                        <th>QTY Sold</th>
                        <th>Subtotal</th>
                        {isAdmin ? <th>Profit</th> : null}
                        {/*  <th>Sales Date</th> */}
                        {isCement ? (
                          <>
                            <th>Loading Charge</th>
                            <th>Offloading Charge</th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {data.sales.map((el, index) => (
                        <tr key={index}>
                          <td>{data?.startIndex + index + 1}</td>
                          <td>{el.Bar_Code}</td>
                          <td>{el.Item_Name}</td>

                          <td>
                            {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    el.qtySold,
                                    el.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : el.qtySold}
                          </td>
                          <td>
                            {currency(el.SubTotal, {
                              symbol: "",
                            }).format()}
                          </td>
                          {isAdmin ? (
                            <td>
                              {currency(el.Profit, {
                                symbol: "",
                              }).format()}
                            </td>
                          ) : null}
                          {/*  <td>
                      {el.Date_Log
                        ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                        : "..."}
                    </td> */}
                          {isCement ? (
                            <>
                              <td>
                                {currency(el?.deliveryInfo?.LoadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                              <td>
                                {currency(el?.deliveryInfo?.OffloadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : queryParams.reportType === "Full" ? (
                  <>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Date</th>
                        <th>{isCement ? "ATC" : "Item Code"}</th>
                        <th>Description</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Subtotal</th>
                        <th>Discount</th>
                        <th>VAT</th>
                        <th>Amount</th>
                        {isAdmin ? <th>Profit</th> : null}
                        <th>Valuation</th>
                        <th>Cost</th>
                        <th>Sales Type</th>
                        <th>Cashier</th>
                        <th>Invoice Number</th>
                        <th>Manufacturer</th>
                        <th>Customer</th>
                        <th>Processby</th>
                        <th>Orderby</th>
                        {isCement ? (
                          <>
                            <th>Loading Charge</th>
                            <th>Offloading Charge</th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {data.sales.map((el, index) => (
                        <tr key={index}>
                          <td>{data?.startIndex + index + 1}</td>
                          <td>
                            {el.Date_Log
                              ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                              : "..."}
                          </td>
                          <td>{el.Bar_Code}</td>
                          <td>{el.Item_Name}</td>
                          <td>
                            {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    el.QTY,
                                    el.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : el.QTY}
                          </td>

                          <td>
                            {currency(el.PriceSold, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.SubTotal, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Discount, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>{0}</td>
                          <td>
                            {currency(el.SubTotal, {
                              symbol: "",
                            }).format()}
                          </td>
                          {isAdmin ? (
                            <td>
                              {currency(el.Profit, {
                                symbol: "",
                              }).format()}
                            </td>
                          ) : null}
                          <td>
                            {currency(el.UnitCost, {
                              symbol: "",
                            })
                              .multiply(el.QTY)
                              .format()}
                          </td>
                          <td>
                            {currency(el.UnitCost, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>{el.OverwriteOfficer}</td>
                          <td>{el.UserName}</td>
                          <td>{el.TransactionID}</td>
                          <td>{el.ProductName}</td>

                          <td>{customerFullName(el.customer)}</td>
                          <td> </td>
                          <td> </td>
                          {isCement ? (
                            <>
                              <td>
                                {currency(el?.deliveryInfo?.LoadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                              <td>
                                {currency(el?.deliveryInfo?.OffloadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : queryParams.reportType === "Detailed" ? (
                  <>
                    <thead>
                      <tr>
                        {queryParams?.customerId && <th>Customer</th>}
                        <th>{isCement ? "ATC" : "Model No"}</th>
                        {!queryParams?.customerId && <th>Item Name</th>}
                        <th>QTY</th>
                        <th>Price Sold</th>
                        <th>Unit Cost</th>
                        <th>Subtotal</th>
                        {isAdmin ? <th>Profit</th> : null}
                        {!queryParams?.customerId && <th>Sales Date</th>}
                        {isCement ? (
                          <>
                            <th>Loading Charge</th>
                            <th>Offloading Charge</th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody>
                      {data.sales.map((el, index) => (
                        <tr key={index}>
                          {queryParams?.customerId && (
                            <td>{customerFullName(el.customer)}</td>
                          )}
                          <td>{el.Bar_Code}</td>
                          {!queryParams?.customerId && <td>{el.Item_Name}</td>}
                          <td>
                            {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    el.QTY,
                                    el.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : el.QTY}
                          </td>
                          <td>
                            {currency(el.PriceSold, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Unit_Price, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.SubTotal, {
                              symbol: "",
                            }).format()}
                          </td>
                          {isAdmin ? (
                            <td>
                              {currency(el.Profit, {
                                symbol: "",
                              }).format()}
                            </td>
                          ) : null}
                          {!queryParams?.customerId && (
                            <td>
                              {el.Date_Log
                                ? format(new Date(el.Date_Log), "yyyy-MM-dd")
                                : "..."}
                            </td>
                          )}
                          {isCement ? (
                            <>
                              <td>
                                {currency(el?.deliveryInfo?.LoadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                              <td>
                                {currency(el?.deliveryInfo?.OffloadingCharge, {
                                  symbol: "",
                                }).format()}
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                    </tbody>
                  </>
                ) : null}
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.sales) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            {!data?.paginationDisabled ? (
              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>

                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={data.count / queryParams.limit}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            ) : (
              <div className="py-2" />
            )}

            {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

            <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                <h2>Summary</h2>
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.totalSales, {
                        symbol: "₦",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Sales</p>
                  </div>

                  {/*  <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div> */}
                </div>

                {isAdmin && (
                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.totalProfit, {
                          symbol: "₦",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Profit</p>
                    </div>
                  </div>
                )}

                <div className="gridChild gridChildBorderLeftBrown d-none">
                  <div className="gridChildLeft">
                    <p className="gridChld1">{0}</p>
                    <p className="gridChld2">Total Losses</p>
                  </div>

                  {/* <div className="gridChildRight">
                <SummaryChartIconDown />
                <p>5%</p>
              </div> */}
                </div>

                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.qtySold, {
                        symbol: "",
                        precision: 0,
                      }).format()}
                    </p>
                    <p className="gridChld2">Quantity Sold</p>
                  </div>

                  {/*   <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div> */}
                </div>

                {isCement ? (
                  <>
                    <div className="gridChild gridChildBorderLeftDarkPurple">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalLoadingCharge, {
                            symbol: "₦",
                            precision: 2,
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Loading Charge</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftBrownGreen">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalOffloadingCharge, {
                            symbol: "₦",
                            precision: 2,
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Offloading Charge</p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
