import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  BookIcon,
  RecieptIcon,
  PageHeaderListIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import NoTableItem from "../utils/NoTableItem";

const paymentTypeLocal = [
  { label: "All", value: "" },
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
  {
    label: "Credit/Debit Card (POS)",
    value: "Credit/Debit Card",
  },
  {
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
];

export default function PaymentReport() {
  useScrollTop();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    PaymentType: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    debit: 0,
    enableDateRange: true,
  };

  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/customer-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el,
      label: el,
    }));
    data.paymentType = data.paymentType.map((el) => ({
      value: el,
      label: el,
    }));
    return data;
  };

  const {
    data = { count: 0, customerLedgers: [] },
    refetch,
    isSuccess,
    isFetching,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer ID</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="customerId"
                        placeholder="Enter Customer ID"
                        value={filterParams?.customerId}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <CustomerSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer Name</Form.Label>
                    <Form.Control
                      name="customerName"
                      value={filterParams?.customerName}
                      placeholder="Enter Customer Name"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Invoice Cat</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={data?.invoiceCat}
                      defaultValue={defaultSelectValue(
                        queryParams?.invoiceCat,
                        data?.invoiceCat,
                        { value: "", label: "All" }
                      )}
                      options={data?.invoiceCat || []}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          invoiceCat: selected.value,
                        });
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Payment Type</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      name="PaymentType"
                      theme={reactSelectTheme}
                      isSearchable={false}
                      key={paymentTypeLocal}
                      defaultValue={defaultSelectValue(
                        queryParams?.PaymentType,
                        paymentTypeLocal,
                        { value: "", label: "All" }
                      )}
                      options={paymentTypeLocal}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          PaymentType: selected.value,
                        });
                      }}
                    />
                  </Form.Group>

                  <hr className="mt-3 mb-4" />

                  <div className="mb-2-5">
                    <Form.Check
                      inline
                      label="Enable Date Range"
                      name="enableDateRange"
                      type={"checkbox"}
                      id={`enableDateRange`}
                      checked={filterParams?.enableDateRange}
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </div>

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="topStart"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}
                Customer Invoices and Payments
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h1>
              <div className="actions">
                {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                {/* <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button> */}

                <button
                  onClick={() => setBtnSummaryActive(true)}
                  className="btn summaryBtn "
                >
                  Show Summary
                </button>
              </div>
            </header>

            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th />
                    <th>Customer Name</th>
                    <th>Trans Date</th>
                    <th>Doc Type</th>
                    <th>Trans ID</th>
                    <th>Payment Type</th>

                    <th>Credit Amount</th>
                    <th>InvoiceCat</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  {data.customerLedgers.map((el, index) => (
                    <tr key={index}>
                      <td>
                        {["invoice", "official receipt"].includes(
                          lowerCase(el.DocType)
                        ) && (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              {lowerCase(el.DocType) === "invoice" && (
                                <Dropdown.Item as="div">
                                  <a
                                    href={`${backendUrl}/api/invoice/pdf/invoice/${
                                      el.Trans_ID
                                    }`}
                                    target="blank"
                                  >
                                    <BookIcon />
                                    Open Invoice
                                  </a>
                                </Dropdown.Item>
                              )}
                              {lowerCase(el.DocType) === "official receipt" && (
                                <Dropdown.Item as="div">
                                  <a
                                    href={`${backendUrl}/api/invoice/pdf/receipt/${
                                      el.Trans_ID
                                    }`}
                                    target="blank"
                                  >
                                    <RecieptIcon />
                                    Open Reciept
                                  </a>
                                </Dropdown.Item>
                              )}
                              {lowerCase(el.DocType) === "invoice" && (
                                <Dropdown.Item as="button">
                                  <a
                                    href={`${backendUrl}/api/invoice/pdf/waybill/${
                                      el.Trans_ID
                                    }`}
                                    target="blank"
                                  >
                                    <PageHeaderListIcon />
                                    Open Waybill
                                  </a>
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>{" "}
                          </Dropdown>
                        )}
                      </td>
                      <td>{customerFullName(el.customer)}</td>
                      <td>
                        {el.Post_Time
                          ? format(new Date(el.Post_Time), "dd MMM, yyyy")
                          : ""}
                      </td>
                      <td>{el.DocType}</td>
                      <td>{el.Trans_ID}</td>
                      <td>{el.PaymentType}</td>

                      <td>
                        {currency(el.Credit, {
                          symbol: "",
                        }).format()}
                      </td>

                      <td>{el.InvoiceCat}</td>
                      <td>{el.Username}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.customerLedgers) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>

            {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

            <div
              className={
                btnSummryActive
                  ? `summaryNew -border-top open`
                  : `summaryNew -border-top`
              }
            >
              <div className="summaryHeader">
                {/*  <h2>Summary</h2> */}
                <div />
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              {/* <div className="gridCont no-bottom-gap">
                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalPaymentAmt, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Payment Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.reversedPaymentAmount, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2"> Reversed Payment Amt</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div> */}

              {/*  <div className="gridCont three-col-grid">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.Cash_Sales, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Cash Sales</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftOrange">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.Credit_Sales, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Credit Sales</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalExpenses, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Expenses</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                {showMoreSummary ? (
                  <>
                    <div className="gridChild gridChildBorderLeftDarkerGreen">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.Cash_PMT, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Cash PMT</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.totalPosted, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Posted</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftDarkPurple">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.posToBank, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">POS to Bank</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftLightYellow">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.Cheque_PMT, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">
                          Total Credit/Debit Card Cheque
                        </p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>

                    <div />

                    <div className="gridChild gridChildBorderLeftBrownGreen">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.directBankTransfer, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Direct Bank Transfer</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftDarkPurple">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.totalCash, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Cash</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div />

                    <div className="gridChild gridChildBorderLeftLightPurple">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.cashSentToBank, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Cash Sent to bank</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div />

                    <div />

                    <div className="gridChild gridChildBorderLeftBrown">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.cashAtHand, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Cash at Hand</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>
                  </>
                ) : (
                  <> </>
                )}
              </div> */}

              <div className="row justify-content-between">
                <div className="col">
                  <div className="mb-3">
                    <div className="d-flex gap-3 align-items-center mb-4">
                      <h2 className="m-0">Payment Report</h2>{" "}
                      <hr className="flex-grow-1 m-0" />
                    </div>

                    <div className="gridCont three-col-grid">
                      <div className="gridChild gridChildBorderLeftDarkPurple">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.cashPayment, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Cash PMT</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftViolet">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.posPayment, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Credit/Debit (POS)</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.directBankTransferPayment, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Direct Bank Transfer</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftBrownGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.chequePayment, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Cheque</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftLightPurple">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.cashPayment, {
                              symbol: "",
                            })
                              .add(data?.posPayment)
                              .add(data?.directBankTransferPayment)
                              .add(data?.chequePayment)
                              .format()}
                          </p>
                          <p className="gridChld2">Total Payment Posted</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-flex gap-3 align-items-center mb-4">
                      <h2 className="m-0">Sales Summary</h2>{" "}
                      <hr className="flex-grow-1 m-0" />
                    </div>

                    <div className="gridCont three-col-grid">
                      <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.cashSales, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Cash Sales</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftLightYellow">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.creditSales, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Credit Sales</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.creditSales, {
                              symbol: "",
                            })
                              .add(data?.cashSales)
                              .format()}
                          </p>
                          <p className="gridChld2">Total Sales Posted</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isAdmin || isCashier ? (
                  <div className="col-md-3">
                    <h2>Other Summary</h2>

                    <div className="gridCont d-flex flex-column">
                      <div className="gridChild gridChildBorderLeftOrange">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.totalExpenses, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Expenses</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftBrown">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.cashSentToBank, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Cash Sent to bank</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftLightYellow">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data.cashAtHand, {
                              symbol: "",
                            }).format()}
                          </p>
                          <p className="gridChld2">Cash at Hand</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
