import "./assets/scss/index.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import DashboardLayout from "./components/DashboardLayout";
import { Test } from "./components/Test";
import InnerPageLayout from "./components/InnerPageLayout";
import { Nav as SalesAndInvoicingNav } from "./components/SalesAndInvoicing/Nav";
import { InvoiceList } from "./components/SalesAndInvoicing/InvoiceList";
import Login from "./components/Login";
import HomeLayout from "./components/HomeLayout";
import MasterLayout from "./components/MasterLayout";
import Hello from "./components/utils/Hello";
import DetailedIcon, {
  BucketIcon,
  PageHeaderListIcon,
} from "./components/Icons";
import Accounts from "./components/Dashboard/Accounts";
import System from "./components/Dashboard/System";
import { Wrapper as SalesAndInvoicingWrapper } from "./components/SalesAndInvoicing/Wrapper";
import CreateInvoice from "./components/SalesAndInvoicing/CreateInvoice";
import ReportsNav from "./components/Reports/ReportsNav";
import CashBook from "./components/Reports/CashBook";
import SalesAnalysis from "./components/Reports/SalesAnalysis";
import SalesRepReports from "./components/Reports/SalesRepReports/SalesRepReports";
import Sales from "./components/Reports/SalesRepReports/Sales";
import Nav from "./components/Reports/SalesRepReports/Nav";
import Customers from "./components/Reports/SalesRepReports/Customers";
import SalesByInvoice from "./components/Reports/SalesByInvoice";
import PaymentReport from "./components/Reports/PaymentReport";
import Debtor from "./components/Reports/SalesRepReports/Debtor";
import Payment from "./components/Reports/SalesRepReports/Payment";
import TransactionAnalysis from "./components/Reports/TransactionAnalysis";
import CustomerTransactionAnalysis from "./components/Reports/CustomerTransactionAnalysis";
import CustomerLedger from "./components/Reports/CustomerLedger";
import LoyaltyCustomers from "./components/Reports/LoyaltyCustomers";
import AccountsPayables from "./components/Reports/AccountsPayables";
import AccountsReceivables from "./components/Reports/AccountsReceivables";
import PurchaseHistory from "./components/Reports/PurchaseHistory";
import StockPositionByDate from "./components/Reports/StockPositionByDate";
import InventoryAnalysis from "./components/Reports/InventoryAnalysis";
import InventoryAnalysisWarehouse from "./components/Reports/InventoryAnalysisWarehouse";
import PrintInventoryByProduct from "./components/Reports/PrintInventoryByProduct";
import VendorsPaymentHistory from "./components/Reports/VendorsPaymentHistory";
import VendorsLedger from "./components/Reports/VendorsLedger";
import Expenses from "./components/Reports/Expenses";
import ImprestAccount from "./components/Reports/ImprestAccount";
import BankAccountAnalysis from "./components/Reports/BankAccountAnalysis";
import GeneralLedger from "./components/Reports/GeneralLedger";
import TrialBalance from "./components/Reports/TrialBalance";
import IncomeStatement from "./components/Reports/IncomeStatement";
import BalanceSheet from "./components/Reports/BalanceSheet";
import Send from "./components/Requisition/Send";
import Request from "./components/Requisition/Request";
import RequisitionNav from "./components/Requisition/RequisitionNav";
import RequisitionWrapper from "./components/Requisition/RequisitionWrapper";
import DetailsModal from "./components/Requisition/DetailsModal";
import PreviewModal from "./components/Requisition/PreviewModal";
import ApproveAndSendModal from "./components/Requisition/ApproveAndSendModal";
import ConversationsModal from "./components/Requisition/Conversation/ConversationsModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { NoMatch } from "./components/utils/NoMatch";
import SalesInventory from "./components/SalesInventory";
import ApprovalIndex from "./components/Approval/ApprovalIndex";
import ApproveInvoiceModal from "./components/Approval/ApproveInvoiceModal";
import { CustomerList } from "./components/SalesAndInvoicing/CustomerList";
import CreateCustomersInvoice from "./components/SalesAndInvoicing/CreateCustomersInvoice";
import CreateMeterInvoice from "./components/SalesAndInvoicing/CreateMeterInvoice";
import CreateIronRodInvoice from "./components/SalesAndInvoicing/CreateIronRodInvoice";
import { useEffectOnce, useIsAdmin } from "./utils/hooks";
import LoadAtc from "./components/LoadAtc";
import TransportReport from "./components/Reports/TransportReport";
import TransportRevenueEstimates from "./components/Reports/TransportRevenueEstimates";
import { useAuth } from "./hooks/useAuth";
import DriverSalary from "./components/Salary/DriverSalary";
import Incentive from "./components/Incentives/Incentive";
import { WarehouseInventoryNav } from "./components/Inventory/WarehouseInventoryNav";
import { InventoryList } from "./components/Inventory/InventoryList";
import { InventoryNav } from "./components/Inventory/InventoryNav";
import InventoryListEntry from "./components/Inventory/InventoryListEntry";
import SupplyCenter from "./components/SupplyCenter";
import ReturnItem from "./components/SalesAndInvoicing/ReturnItem";
import PostPayment from "./components/PostPayment";
import CementPriceSettings from "./components/CementPriceSettings";
import CreateProformaInvoice from "./components/SalesAndInvoicing/CreateProformaInvoice";
import { ItemIssuer } from "./components/Inventory/ItemIssuer";
import ReceiveInventory from "./components/Receive/ReceiveInventory";
import DeleteInvoice from "./components/SalesAndInvoicing/DeleteInvoice";
import ReturnItemFromBranch from "./components/Inventory/ReturnItemFromBranch";
import { InventoryManagerNav } from "./components/Inventory/InventoryManagerNav";
import DamagesReturnRecorder from "./components/Inventory/DamagesReturnRecorder";
import ExpensesEntry from "./components/ExpensesEntry";
import Cookies from "universal-cookie";
import CreditDebitMemo from "./components/creditDebitMemo";
import CreateCreditDebitMemo from "./components/CreateCreditDebitMemo";
import Authorize from "./components/Authorize";

function App() {
  let location = useLocation();
  const isAdmin = useIsAdmin();

  const {
    isTransportAndLogistics,
    isIronRod,
    isCement,
    isOilAndGas,
    user: authUser,
  } = useAuth();

  let state = location?.state;
  // let url = new URL(document.location.href);

  // Get company from url first, next get from state
  // then set company here before we render our content

  /*  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  }, [location]); */

  /*   useEffectOnce(() => {
    let url = new URL(document.location.href);
    url = searchParamsToObject(url.searchParams.entries());

    // fot gigc
    if (url.authorize && !appSettings.isBatchStandard) {
      if (isEmpty(authUser)) {
      }
    }
  }); */

  useEffectOnce(() => {
    const cookies = new Cookies();
    const token = cookies.get("x-access-token");
    if (!token && authUser.token) {
      cookies.set("x-access-token", authUser.token, { path: "/" });
    }
  });

  return (
    <div className="app" key={authUser?.company}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Routes location={state?.backgroundLocation || location}>
        <Route path="">
          <Route index element={<Navigate to="dashboard" />} />
          <Route element={<HomeLayout />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="authorize" element={<Authorize />} />
        </Route>

        <Route element={<MasterLayout />}>
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="accounts-dashboard" element={<Accounts />} />
            <Route path="system-admin-tool" element={<System />} />
          </Route>
          <Route path="sales-inventory" element={<SalesInventory />} />
          <Route path="credit-Debit-memo" element={<CreditDebitMemo />} />
          <Route
            path="create-credit-Debit-memo"
            element={<CreateCreditDebitMemo />}
          />
          <Route path="expenses-entry" element={<ExpensesEntry />} />

          {isCement && (
            <>
              <Route path="load-atc" element={<LoadAtc />} />
              {isAdmin && (
                <Route
                  path="price-settings"
                  element={<CementPriceSettings />}
                />
              )}
            </>
          )}
          <Route path="incentives" element={<Incentive />} />
          <Route path="driver-salary" element={<DriverSalary />} />
          <Route path="supply-center" element={<SupplyCenter />} />
          <Route path="post-payments" element={<PostPayment />} />
          <Route path="expenses-entry" element={<ExpensesEntry />} />

          <Route basename path="reports">
            <Route
              element={
                <InnerPageLayout
                  Nav={<ReportsNav />}
                  name="Report Center"
                  description="Your current sales summary and activity"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route index element={<Navigate to="payment-report" />} />
              {/*  <Route path="cash-book" element={<CashBook />} /> */}
              <Route path="payment-report" element={<PaymentReport />} />
              <Route path="sale-by-invoice" element={<SalesByInvoice />} />

              <Route
                path="sales-analysis"
                element={
                  isTransportAndLogistics ? (
                    <TransportReport />
                  ) : (
                    <SalesAnalysis />
                  )
                }
              />

              <Route
                path="sales-rep-reports"
                element={<SalesRepReports Nav={<Nav />} />}
              >
                <Route index element={<Navigate to="sales" />} />
                <Route index path="sales" element={<Sales />} />
                <Route index path="customers" element={<Customers />} />
                <Route index path="payment" element={<Payment />} />
                <Route index path="debtors" element={<Debtor />} />
              </Route>

              <Route
                path="transaction-analysis"
                element={<TransactionAnalysis />}
              />
              <Route
                path="customer-transactions-analysis"
                element={<CustomerTransactionAnalysis />}
              />
              <Route path="customer-ledger" element={<CustomerLedger />} />
              <Route path="loyalty-customers" element={<LoyaltyCustomers />} />
              <Route
                path="accounts-recievables"
                element={<AccountsReceivables />}
              />
              <Route path="accounts-payables" element={<AccountsPayables />} />
              <Route path="purchase-history" element={<PurchaseHistory />} />
              <Route
                path="stock-position-by-date"
                element={<StockPositionByDate />}
              />
              <Route
                path="inventory-analysis"
                element={<InventoryAnalysis />}
              />
              <Route
                path="inventory-analysis-warehouse"
                element={<InventoryAnalysisWarehouse />}
              />
              <Route
                path="print-inventory-by-product"
                element={<PrintInventoryByProduct />}
              />
              <Route
                path="vendors-payment-history"
                element={<VendorsPaymentHistory />}
              />
              <Route path="vendors-ledger" element={<VendorsLedger />} />
              <Route path="expenses" element={<Expenses />} />
              <Route path="imprest-account" element={<ImprestAccount />} />
              <Route
                path="bank-account-analysis"
                element={<BankAccountAnalysis />}
              />
              <Route path="general-ledger" element={<GeneralLedger />} />
              <Route path="trial-balance" element={<TrialBalance />} />
              <Route path="income-statement" element={<IncomeStatement />} />
              <Route path="balance-sheet" element={<BalanceSheet />} />

              <Route path="transport" element={<TransportReport />} />
              <Route
                path="transport-revenue-estimates"
                element={<TransportRevenueEstimates />}
              />
            </Route>
          </Route>
          <Route path="sales-and-invoicing">
            <Route
              element={
                <InnerPageLayout
                  name="Sales Center"
                  description="Manage invoice and create a new invoice."
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route
                element={
                  <SalesAndInvoicingWrapper Nav={<SalesAndInvoicingNav />} />
                }
              >
                <Route index element={<Navigate to="invoice-list" />} />
                <Route path="invoice-list" element={<InvoiceList />} />
                <Route path="customer-list" element={<CustomerList />} />
                <Route path="inventory" element={<InventoryListEntry />} />
                {/*    <Route path="manage-transaction" element={<Test />} />  */}
              </Route>
            </Route>

            <Route
              path="create-invoice"
              element={
                isIronRod ? (
                  <CreateIronRodInvoice />
                ) : isCement ? (
                  <CreateCustomersInvoice />
                ) : isOilAndGas ? (
                  <CreateMeterInvoice />
                ) : (
                  <CreateInvoice />
                )
              }
            />

            <Route
              path="create-proforma-invoice"
              element={<CreateProformaInvoice />}
            />

            {/* Create invoices  */}
            <Route
              path="create-customers-invoice"
              element={<CreateCustomersInvoice />}
            />
            <Route
              path="create-meter-invoice"
              element={<CreateMeterInvoice />}
            />
            <Route
              path="create-iron-rod-invoice"
              element={<CreateIronRodInvoice />}
            />
            {/*  */}
            <Route path="return-invoice-item" element={<ReturnItem />} />
            <Route path="delete-invoice" element={<DeleteInvoice />} />
          </Route>
          <Route
            path="requisition"
            element={
              <InnerPageLayout
                name="Requisition Service"
                description="your submission activity and request acceptance"
                icon={<PageHeaderListIcon />}
              />
            }
          >
            <Route element={<RequisitionWrapper Nav={<RequisitionNav />} />}>
              <Route index element={<Navigate to="request" />} />
              <Route path="send" element={<Send />} />
              <Route exact path="request" element={<Request />}>
                <Route path="details/:id" element={<DetailsModal />} />
                <Route path="preview/:id" element={<PreviewModal />} />
                <Route
                  path="approve-and-send/:id"
                  element={<ApproveAndSendModal />}
                />
                <Route
                  path="conversations/:id"
                  element={<ConversationsModal />}
                />
              </Route>
            </Route>
          </Route>
          <Route exact path="approval" element={<ApprovalIndex />}>
            <Route path="details/:id" element={<ApproveInvoiceModal />} />
          </Route>
          <Route basename path="inventory-for-sales">
            <Route
              element={
                <InnerPageLayout
                  name="Inventory for Sales"
                  description="Manage and organize your inventory"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route
                element={<SalesAndInvoicingWrapper Nav={<InventoryNav />} />}
              >
                <Route index element={<Navigate to="inventory-entry" />} />
                <Route
                  path="inventory-entry"
                  element={<InventoryListEntry />}
                />

                <Route path="item-issuer" element={<ItemIssuer />} />
                <Route
                  exact
                  path="inventory-manager"
                  element={<InnerPageLayout Nav={<InventoryManagerNav />} />}
                >
                  <Route
                    index
                    element={<Navigate to="damages-return-recorder" />}
                  />
                  {/*  <Route
                    path="return-item-from-branch"
                    element={<ReturnItemFromBranch />}
                  /> */}
                  <Route
                    path="damages-return-recorder"
                    element={<DamagesReturnRecorder />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          {/* <Route basename path="receive-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Recieve Inventory"
                  description="Receive all your inventory"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route
                element={<SalesAndInvoicingWrapper Nav={<InventoryNav />} />}
              >
                <Route index element={<Navigate to="receiving-inventory" />} />
                <Route
                  path="inventory-entry"
                  element={<InventoryListEntry />}
                />

                <Route path="item-issuer" element={<ItemIssuer />} />
              </Route>
            </Route>
          </Route> */}
          <Route
            exact
            path="receive-inventory"
            element={<ReceiveInventory />}
          />
          <Route path="warehouse-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Warehouse Inventory"
                  description="Manage and organize your Warehouse inventory"
                  icon={<BucketIcon />}
                />
              }
            >
              <Route
                element={
                  <SalesAndInvoicingWrapper Nav={<WarehouseInventoryNav />} />
                }
              >
                <Route index element={<Navigate to="inventory-list" />} />
                <Route path="inventory-list" element={<InventoryList />} />
                <Route path="item-issuer" element={<Test />} />
                <Route path="branches-inventory" element={<Test />} />
                <Route path="inventory-manager" element={<Test />} />
                <Route path="vendor" element={<Test />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
      {/*state?.backgroundLocation && (
        <Routes>
          <Route path="requisition">
            <Route
              path="preview/:id"
              element={<PreviewModal location={location} />}
            />
            <Route
              path="approve-and-send/:id"
              element={<ApproveAndSendModal location={location} />}
            />
            <Route
              path="conversations/:id"
              element={<ConversationsModal location={location} />}
            />
          </Route>
      </Routes > }
      )*/}
    </div>
  );
}

export default App;
