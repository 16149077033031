import { upperFirst } from "lodash";
import "./../../assets/scss/scoped/avatar.scoped.scss";

export default function Avatar({
  name,
  company = "",
  department = "",
  ...props
}) {
  return (
    <div className="rounded-circle avatar" {...props}>
      <div
        className="d-flex align-items-center justify-content-center w-100 h-100"
        title={`${name} - ${department} ${company ? `(${company})` : ""}`}
      >
        <span className="fw-bold fs-6">
          {upperFirst(name ? name[0] : "...")}
        </span>
      </div>
    </div>
  );
}
