import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  DropdownEyeIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  LinkVariantIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  paginationOptions,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet, useNavigate } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { toast } from "react-toastify";
import eventBus from "../../utils/EventBus";
import { filter, isEmpty } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";

export default function ApprovalIndex() {
  //  useScrollTop();
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const initialFilterParams = { q: "" };

  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchPendingPermits = async (queryParams) => {
    console.log(queryParams);
    let response = await fetch(
      `${backendUrl}/api/permits?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { pendingPermits: [] }, refetch } = useQuery(
    [reportActions.PENDING_PERMITS, queryParams],
    () => fetchPendingPermits(queryParams),
    {
      keepPreviousData: true,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const viewDetails = (el) => {
    el.TransactionID && navigate(`details/${el.TransactionID}`);
  };

  const updateAllPermit = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          [reportActions.PENDING_PERMITS, queryParams],
          (data) => {
            /* if (status === "Approved") {
              data.pendingPermits = data.pendingPermits.map((el) => {
                if (transactionIds.includes(el.TransactionID)) {
                  return {
                    ...el,
                    Status: status,
                  };
                }
              });
            } else if (status === "Disapproved") { */
            data.pendingPermits = data.pendingPermits.filter(
              (el) => !transactionIds.includes(el.TransactionID)
            );
            //  }
            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const approveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Approve all",
        description: "Are you sure, you want to approve all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds,
      });
    }
  };

  const disapproveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove all",
        description: "Are you sure, you want to disapprove all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds,
      });
    }
  };

  const approve = async (el) => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  const disapprove = async (el) => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  /* eventBus.useCustomEventListener(
    eventBus.APPROVE_ALL_PERFORMA_INVOICE,
    disapproveAll
  );

  eventBus.useCustomEventListener(
    eventBus.DISAPPROVE_ALL_PERFORMA_INVOICE,
    approveAll
  ); */

  eventBus.useCustomEventListener("FETCH_PENDING_PERMITS", refetch);

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Approval Pending Invoice"
        description="Your current pending Invoice"
        icon={<ChartPieSliceTitleIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header className="flex-wrap gap-3">
                <div className="d-flex flex-grow-1">
                  <div className="global-search-area col col-md-6">
                    <MagnifyIcon />
                    <Form.Control
                      id="queryParams-q"
                      className=""
                      name="q"
                      value={filterParams.q}
                      onChange={(e) => handleSearchQueryChange(e)}
                      placeholder="Search..."
                    />
                  </div>
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary mx-3"
                  >
                    <CachedIcon />
                  </button>
                </div>

                <div className="d-flex gap-3">
                  <Button
                    disabled={isEmpty(data?.pendingPermits)}
                    onClick={() => disapproveAll()}
                    variant="danger"
                    className="text-white px-3"
                  >
                    Disapprove All
                  </Button>

                  <Button
                    disabled={isEmpty(data?.pendingPermits)}
                    onClick={() => approveAll()}
                  >
                    Approve all
                  </Button>
                </div>
              </header>

              <div className="px-md-4">
                <Table
                  borderless
                  responsive
                  hover
                  striped
                  className="product-table"
                >
                  <thead>
                    <tr>
                      <th>TransID</th>
                      <th>Total Value</th>
                      <th>Customer</th>
                      <th>Sent By</th>
                      <th> {"  "}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.pendingPermits.map((el, index) => (
                      <tr className="p-cursor" key={index}>
                        <td onClick={() => viewDetails(el)}>
                          {el.TransactionID}
                        </td>
                        <td onClick={() => viewDetails(el)}>
                          {currency(el.totalValue, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td onClick={() => viewDetails(el)}>
                          {customerFullName(el.customer)}
                        </td>

                        <td onClick={() => viewDetails(el)}>{el.UserName}</td>
                        <td className="position-relative">
                          <div className="d-flex gap-3">
                            {/*  <Link
                            to={`/approval/details/${el.TransactionID}`}
                            className="btn border bg-white text-primary d-flex align-items-center"
                          >
                            View Details
                          </Link> */}

                            <Button
                              className="ga-btn"
                              onClick={() => approve(el)}
                            >
                              Approve
                            </Button>
                            <Button
                              className="ga-btn"
                              variant="outline-danger"
                              onClick={() => disapprove(el)}
                            >
                              Disapprove
                            </Button>

                            <Dropdown style={{ margin: 0 }}>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-light-blue text-primary"
                                bsPrefix="print more"
                              >
                                <DotsHorizontalIcon />
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="button"
                                  onClick={() =>
                                    copyText(
                                      `${
                                        services.frontendUrl
                                      }/approval/details/${el.TransactionID}`
                                    )
                                  }
                                >
                                  <LinkVariantIcon />
                                  Copy Link
                                </Dropdown.Item>

                                <Dropdown.Item as="div">
                                  <Link
                                    to={`/approval/details/${el.TransactionID}`}
                                  >
                                    <DropdownEyeIcon /> View Details
                                  </Link>
                                </Dropdown.Item>
                                {/* 
                              <Dropdown.Item as="button" onClick={() => {}}>
                                <DropdownCheckIcon /> Appove
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                className="text-danger"
                                onClick={() => {}}
                              >
                                <DropdownCloseIcon /> Reject
                              </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Outlet />
    </main>
  );
}
