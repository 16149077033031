import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { qtyFormat } from "../utils/helpers";

function UpdateBatchTons({ proceed, show, batch, Tons, Pieces, formatType }) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    UnitCost: "",
    UnitPrice: "",
    Tons: "",
    Pieces: "",
  };
  const schema = yup.object().shape({
    UnitCost: yup.string().required(),
    UnitPrice: yup.string().required(),
    // Pieces: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      proceed(values);
    },
  });

  const setUp = () => {
    formik.setValues({
      UnitCost: batch.UnitCost,
      UnitPrice: batch.UnitPrice,
      Tons,
      Pieces,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const canClose = () => {
    proceed();
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Edit Batch {batch.Bar_Code ? batch.Bar_Code.split("-")[1] : "..."}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>UnitCost</Form.Label>
            <CurrencyCustomInput
              type="text"
              placeholder="Enter Unit Cost"
              name="UnitCost"
              value={formik.values.UnitCost}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitCost}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>UnitPrice</Form.Label>
            <CurrencyCustomInput
              type="text"
              placeholder="Enter Unit Price"
              name="UnitPrice"
              value={formik.values.UnitPrice}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitPrice && !!formik.errors.UnitPrice}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          {formatType !== "normal" ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Tons</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Tons"
                  name="Tons"
                  value={formik.values.Tons}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Pieces && !!formik.errors.Tons}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Tons}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Pieces</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Pieces"
                  name="Pieces"
                  value={formik.values.Pieces}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Pieces}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>Quantity</Form.Label>
              <NumberCustomInput
                type="text"
                placeholder="Enter Quantity"
                name="Pieces"
                value={formik.values.Pieces}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Pieces}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Save"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function BatchQuantityDialog(props) {
  return createConfirmation(confirmable(UpdateBatchTons))({ ...props });
}
