import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/customer-selector.scoped.scss";
import queryString from "query-string";
import CloseIcon from "mdi-react/CloseIcon";
import { useAuth } from "../../hooks/useAuth";

export default function CustomerSelector({
  setShowCustomerSelector,
  handleSelectedCustomer,
  selectedCustomer = {},
  withCredit = false,
  withPaymentDue = false,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    LastName: "",
    PhoneNo1: "",
    withCredit,
    withPaymentDue,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { customers: [] } } = useQuery(
    [queryActions.CUSTOMERS, debouncedqueryParams],
    () => getCustomer(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="customer-table-area rounded">
      <div className="search-area">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="mb-0">All Customers From Database</h2>

          <Button
            onClick={() => setShowCustomerSelector(false)}
            variant="white"
            className="p-0 text-gray"
            size="sm"
          >
            <CloseIcon />
          </Button>
        </div>

        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Input Last Name..."
              name="LastName"
              className="h-42"
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  LastName: e.target.value,
                })
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              placeholder="Input No."
              name="PhoneNo1"
              type="tel"
              className="h-42"
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  PhoneNo1: e.target.value,
                })
              }
            />
          </Form.Group>
        </Form>
      </div>

      <div className="content px-3 pe-2">
        <Table borderless striped responsive className="product-table">
          <thead className="sticky border-bottom">
            <tr>
              <th scope="col">Customer ID</th>
              <th scope="col">Customer Name</th>
            </tr>
          </thead>

          <tbody className="blue-hover">
            {data.customers &&
              data.customers.map((el, index) => (
                <tr
                  key={index}
                  onClick={() => handleSelectedCustomer(el)}
                  className={`p-cursor ${
                    selectedCustomer?.Cust_ID === el.Cust_ID ? "active-row" : ""
                  } `}
                >
                  <td>{el.Cust_ID}</td>
                  <td>{el.LastName}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
