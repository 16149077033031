import { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertMomentDate,
  customerFullName,
  maxTopPopperConfig,
  reactSelectTheme,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { isEmpty } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useEffectOnce,
  useToggle,
  useUpdateEffect,
} from "../../utils/hooks";
import CustomerSelectorPopover from "../utils/CustomerSelectorPopover";
import { Popover } from "react-tiny-popover";
import AddCustomerItemModal from "./AddCustomerItemModal";
import moment, { isMoment } from "moment";
import { format } from "date-fns";
import DatePicker from "react-datepicker";

export default function MeterEditRow({
  index,
  editMeterSale,
  sale,
  handleRemoveMeter,
  handleEditCustomer,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
  ];

  const taxOptions = [
    {
      label: "None",
      value: "None",
      percentage: 0,
    },
    {
      label: "VAT",
      value: "VAT",
      percentage: 0.075,
    },
    {
      label: "WHT",
      value: "WHT",
      percentage: 0.05,
    },
    {
      label: "NCD",
      value: "NCD",
      percentage: 0.01,
    },
  ];

  const [salesDateToggleState, setSalesDateToggleState] = useState(false);

  const formik = useFormik({
    initialValues: {
      tax: "",
      taxType: "None",
      splitPaymentType: "",
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      proformaNumber: "",
      dueIn: 1,
      PaymentType: "Cash",
      Quantity: 0,
      PriceSold: 0,
      Discount: 0,
      salesDate: moment(),
      shippingCost: "",
      openingMeterReading: 0,
      closingMeterReading: 0,
    },
    validationSchema: yup.object().shape({
      // PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
      /*  Quantity: yup
        .string()
        .required("required")
        .min(1),
      PriceSold: yup.string().required("required"), */
    }),
    onSubmit: (values) => {
      //  alert(JSON.stringify(values));
      /* tableData[editedItemIndex].sales[editedSaleIndex].Quantity =
        values.Quantity;
      setTableData([...tableData]);
      unsetActiveRow(); */
    },
    onReset: () => {},
  });

  const submitForm = () => {
    //  formik.submitForm();
  };

  const formValues = useDebounce(formik.values, 500);

  // form should populate once
  const populateForm = () => {
    formik.setValues({
      ...formik.values,
      UnitPrice: sale.UnitPrice,
      amountPaid: sale.UnitPrice,
      PriceSold: sale.UnitPrice,
      salesDate: sale.salesDate,
      shippingCost: sale.shippingCost,
      openingMeterReading: sale.openingMeterReading,
      closingMeterReading: sale.closingMeterReading,
    });
  };

  useEffectOnce(() => {
    populateForm();
  });

  useMemo(() => {
    let qty = currency(formik.values.closingMeterReading, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.openingMeterReading)
      .format();
    formik.setFieldValue("Quantity", qty);
  }, [formik.values.openingMeterReading, formik.values.closingMeterReading]);

  const subTotal = useMemo(() => {
    if (formik.values.Discount) {
      return currency(formik.values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format();
    } else {
      return currency(formik.values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(formik.values.Quantity)
        .format();
    }
  }, [formik.values.PriceSold, formik.values.Discount, formik.values.Quantity]);

  const discount = useMemo(() => {
    return currency(formik.values.Discount, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.Discount, formik.values.Quantity]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .subtract(discount)
      .multiply(100)
      .divide(100)
      .add(taxValue)
      .format();

    formik.setFieldValue("amountPaid", sum);
    return sum;
  }, [subTotal, discount, taxValue]);

  useEffect(() => {
    if (formik.values.Discount) {
      const finalSellingPrice = currency(formik.values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .subtract(formik.values.Discount)
        .format();
      formik.setFieldValue("PriceSold", finalSellingPrice);
      // formik.setFieldValue("amountPaid", finalSellingPrice);
    }
  }, [formik.values.Discount]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const balance = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.amountPaid)
      .format();
  }, [amountDue, formik.values.amountPaid]);

  const profit = useMemo(() => {
    return currency(formik.values.PriceSold, {
      symbol: "",
      separator: "",
    })
      .subtract(sale.UnitCost)
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.PriceSold, sale.UnitCost, formik.values.Quantity]);

  const costOFSales = useMemo(() => {
    return currency(sale.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.Quantity]);

  useEffect(() => {
    editMeterSale({
      index,
      formValues: {
        ...formValues,
        Profit: profit,
        balance,
        SubTotal: subTotal,
        profit,
        amountDue,
        invoiceCat: sale.Product_Name,
        taxValue,
        costOFSales,
      },
    });
  }, [formValues, profit, balance, subTotal, amountDue, taxValue, costOFSales]);
  return (
    <tr className="p-cursor bg-white active-invoice-row popup">
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVeritcalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => handleRemoveMeter(index)}
            >
              <DeleteIcon />
              Remove Meter
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>

      <td>
        {sale.Meter_Name} - {sale.Meter_Type}
      </td>
      <td>
        <Button
          onClick={() => handleEditCustomer(index)}
          variant={!sale.customer ? "outline-primary" : ""}
          className="border-0"
          title="Select Customer"
          type="button"
        >
          {sale.customer?.LastName || "Select Customer"}
        </Button>
      </td>
      <td>{sale.Bar_Code}</td>
      <td>{sale.Item_Name}</td>

      <td>
        <NumberCustomInput
          name="openingMeterReading"
          value={formik.values.openingMeterReading}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={
            formik.touched.openingMeterReading &&
            !!formik.errors.openingMeterReading
          }
          onBlur={() => formik.setFieldTouched("openingMeterReading", true)}
          autocomplete="off"
        />
      </td>
      <td>
        <NumberCustomInput
          name="closingMeterReading"
          value={formik.values.closingMeterReading}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={
            formik.touched.closingMeterReading &&
            !!formik.errors.closingMeterReading
          }
          onBlur={() => formik.setFieldTouched("closingMeterReading", true)}
          autocomplete="off"
        />
      </td>
      <td>
        {currency(formik.values.Quantity, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <CurrencyCustomInput
          name="PriceSold"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.PriceSold}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
            formik.setFieldValue("Discount", 0);
          }}
          isInvalid={formik.touched.PriceSold && !!formik.errors.PriceSold}
          onBlur={() => formik.setFieldTouched("PriceSold", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
          <span className="custom-invalid-feedback">
            {formik.errors.PriceSold}
          </span>
        ) : null}
      </td>

      <td>
        <CurrencyCustomInput
          name="Discount"
          placeholder="0.00"
          currencySymbol=""
          value={formik.values.Discount}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.Discount && !!formik.errors.Discount}
          onBlur={() => formik.setFieldTouched("Discount", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
        />
        {formik.touched.Discount && !!formik.errors.Discount ? (
          <span className="custom-invalid-feedback">
            {formik.errors.Discount}
          </span>
        ) : null}
      </td>
      <td>{sale.Product_Name}</td>
      <td>
        {currency(sale.UnitPrice, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(profit, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(sale.UnitCost, {
          symbol: "",
        })
          .multiply(formik.values.Quantity)
          .format()}
      </td>
      <td>{sale.Item_Type} </td>

      <td>
        <CurrencyCustomInput
          name="shippingCost"
          currencySymbol=""
          value={formik.values.shippingCost}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={
            formik.touched.shippingCost && !!formik.errors.shippingCost
          }
          onBlur={() => formik.setFieldTouched("shippingCost", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
          placeholder="0.00"
        />
        {formik.touched.shippingCost && !!formik.errors.shippingCost ? (
          <span className="custom-invalid-feedback">
            {formik.errors.shippingCost}
          </span>
        ) : null}
      </td>

      {/*  calculations   */}
      <td>
        <Popover
          reposition={false}
          key={index}
          isOpen={salesDateToggleState}
          onClickOutside={() => setSalesDateToggleState(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              timeFormat={false}
              // closeOnSelect={true}
              // closeOnClickOutside={true}
              dateFormat="MMM DD, YYYY"
              name="deliveryDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.salesDate && !!formik.errors.salesDate
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              value={formik.values.salsDate}
              input={false}
              onChange={(date) => {
                formik.setFieldValue("salesDate", date, true);
                setSalesDateToggleState(false);
              }}
              onBlur={() => formik.setFieldTouched("salesDate", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setSalesDateToggleState(!salesDateToggleState)}
            value={convertMomentDate(formik.values.salesDate)}
          />
        </Popover>
      </td>
      <td>
        <Form.Control
          type="text"
          placeholder="Po No."
          name="proformaNumber"
          value={formik.values.poNumber}
          onChange={formik.handleChange}
        />
      </td>
      <td>
        <Select
          classNamePrefix="form-select"
          placeholder="Select"
          menuPosition="fixed"
          menuShouldScrollIntoView={false}
          isSearchable={false}
          value={taxOptions.find((el) => el.value === formik.values.taxType)}
          options={taxOptions}
          onChange={({ value }) => formik.setFieldValue("taxType", value)}
        />
      </td>
      <td>
        <CurrencyCustomInput
          name="tax"
          value={taxValue}
          onValueChange={(value, name) => {
            //   formik.setFieldValue(name, value);
          }}
          placeholder="0.00"
          currencySymbol=""
        />
      </td>
      <td>
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={({ value }) => formik.setFieldValue("PaymentType", value)}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        />
      </td>
      <td>
        {currency(subTotal, {
          symbol: "",
        }).format()}
      </td>
      <td>
        {currency(amountDue, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <CurrencyCustomInput
          name="amountPaid"
          currencySymbol=""
          value={formik.values.amountPaid}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          isInvalid={formik.touched.amountPaid && !!formik.errors.amountPaid}
          onBlur={() => formik.setFieldTouched("amountPaid", true)}
          onKeyDown={(e) => e.keyCode === 13 && submitForm()}
          placeholder="0.00"
        />
        {formik.touched.amountPaid && !!formik.errors.amountPaid ? (
          <span className="custom-invalid-feedback">
            {formik.errors.amountPaid}
          </span>
        ) : null}
      </td>
      <td>
        {currency(balance, {
          symbol: "",
        }).format()}
      </td>
      <td>
        <NumberCustomInput
          name="dueIn"
          value={formik.values.dueIn}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>
    </tr>
  );
}
