import { useEffect, useState, useRef, Fragment } from "react";
import {
  Button,
  Modal,
  Table,
  InputGroup,
  Popover,
  Overlay,
  Form,
} from "react-bootstrap";
import ArrowCollapseDownIcon from "mdi-react/ArrowCollapseDownIcon";
import "./../../assets/scss/requisition/index.scss";
import { Logo, PrintIcon, SignatureIcon } from "../Icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { format } from "date-fns";
import CurrencyInput from "react-currency-input-field";
import { find, lowerCase } from "lodash";
import {
  isInventoryOrFundRequest,
  maxTopPopperConfig,
  searchParamsToObject,
  styleHelper,
  waitFor,
} from "../../utils/helpers";
import useDebounce, { useCanGoBack, useHistoryStack } from "../../utils/hooks";
import { toast } from "react-toastify";
import { requisitionActions } from "../../utils/reactQueryActions";
import currency from "currency.js";

import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { last } from "lodash";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { ApproveAndCloseDialog, RejectDialog } from "./Dialog";
import printJS from "print-js";

export default function PreviewModal({ location }) {
  const initialFundRequest = {
    description: "",
    unitCost: "",
    quantity: "",
  };
  let { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const goBack = useCanGoBack();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [fundRequest, setFundRequest] = useState(initialFundRequest);
  const { user: authUser, backendUrl } = useAuth();
  const [file, setFile] = useState();

  const getRequisitionDetails = async (id) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${id}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (isInventoryOrFundRequest(data.requisition.type)) {
      setTableData(
        pullDataFromString(data.requisition.description, data.requisition.type)
      );
    }
    return data;
  };

  const {
    error,
    data: { requisition, company } = { requisition: null, company: null },
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, id],
    () => getRequisitionDetails(id),
    {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (data) => {},
    }
  );

  useEffect(() => {
    if (requisition) {
      const queryParams = searchParamsToObject(searchParams.entries());
      if (queryParams.action) {
        if (canPerformAction(requisition)) {
          if (queryParams.action === "approveAndClose") {
            approveAndClose();
          } else if (queryParams.action === "reject") {
            reject();
          }
        }
        delete queryParams.action;
        setSearchParams({ ...queryParams });
      }
    }
  }, [requisition]);

  const reject = async () => {
    await RejectDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
    });
  };

  const approveAndClose = async () => {
    await ApproveAndCloseDialog({
      authUser,
      requisitionTitle: requisition.title,
      requisition: last(requisition?.requisitiontracks),
      previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
    });
  };

  const pullDataFromString = (text, requisitionType) => {
    let res = "";
    if (lowerCase(requisitionType) === "fund request") {
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            description: data[0],
            unitCost: Number(data[1]).toFixed(2),
            quantity: data[2],
            subtotal: data[1] * data[2],
          };
        })
        .filter((el) => el.description);
      console.log(res);
      return res;
    } else if (lowerCase(requisitionType) === "inventory") {
      console.log(text);
      res = text
        .split("&&&")
        .filter((el) => el)
        .map((el) => {
          const data = el.split(",,,");
          return {
            barcode: data[0],
            itemName: data[1],
            unitCost: Number(data[2]).toFixed(2),
            quantity: data[3],
            subtotal: data[2] * data[3],
          };
        })
        .filter((el) => el.barcode);
      console.log(res);
      return res;
    }

    /*res = text
      .replaceAll("SELECTED INVENTORY ITEMS", "")
      .replaceAll("Barcode:", "#_$_%")
      .replaceAll("Item Name:", "#_$_%")
      .replaceAll("Quantity:", "#_$_%")
      .replaceAll(/(\r\n|\n|\r)/gm, "")
      .replace(/\s+/g, "")
      .trim()
      .split("#_$_%")
      .filter((el) => el);

    res = chunk(res, 3).map((el) => ({
      Barcode: el[0],
      ItemName: el[1],
      Quantity: el[2],
    }));

    return res;*/
  };

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const calculateTotal = (tableData) => {
    setTotal(
      tableData
        .map((el) =>
          currency(el.unitCost, {
            precision: 2,
          }).multiply(el.quantity)
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        )
    );
  };

  const debouncedTableData = useDebounce(tableData, 1000);
  useEffect(() => {
    if (debouncedTableData) calculateTotal(debouncedTableData);
  }, [debouncedTableData]);

  const updateRequisition = async (payload) => {
    // await waitFor(5000);
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    // formData.append("signature", file, `${authUser.Name}_signature`);

    let response = await fetch(`${backendUrl}/api/requisition/update/${id}`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const updateRequisitionMutation = useMutation(
    (payload) => updateRequisition(payload),
    {
      onSuccess: ({ description }) => {
        queryClient.setQueryData(
          [requisitionActions.GET_REQUISITION_DETAILS, id],
          (data) => {
            data.requisition.description = description;
            return data;
          }
        );
        // setFile(null);
        toast.success("Requisition updated successfully");
      },
      onError: () => {
        toast.error("Unable to save");
      },
    }
  );

  const save = () => {
    // if (!file) toast.error("Please attach your signature");
    let description = "";
    if (lowerCase(requisition?.type) === "fund request") {
      description = [
        ...tableData,
        // add if form has data
        ...(fundRequest.description ? [fundRequest] : []),
      ]
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    } else if (lowerCase(requisition?.type) === "inventory") {
      description = tableData
        .map((el) => {
          delete el.subtotal;
          return Object.values(el).join(",,,");
        })
        .join("&&&");
    }

    description = description + "&&&";
    updateRequisitionMutation.mutate({
      requestid: id,
      description,
      file,
      amount: total,
    });
  };

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
    //  setActiveTableIndex(index);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    console.log(item);
    /* Bar_Code: "0003";
    Branch: "HQ";
    Cat_Name: "A2 Lubricant";
    Item_Name: "A2 Oil 1L";
    Quantity: 12;
    UnitCost: 1450;
    UnitPrice: 1650;
    imagepath: ""; */
    if (lowerCase(requisition?.type) === "inventory") {
      item = {
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
        unitCost: item.UnitCost,
        quantity: 1,
      };
    } else if (lowerCase(requisition?.type) === "fund request") {
    }

    let oldTableData = tableData;

    if (activeTableIndex === -1) {
      oldTableData = [...oldTableData, item];
    } else {
      oldTableData[activeTableIndex] = item;
    }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  const handleFundRequestInput = (value, name) => {
    console.log(value, name);
    setFundRequest({
      ...fundRequest,
      [name]: value,
    });
  };

  const createNewFundRequest = (e) => {
    if (!e.target.value) return;
    setTableData([...tableData, fundRequest]);
    e.target.blur();

    setTimeout(() => {
      setFundRequest({ ...initialFundRequest });
    }, 0);
  };

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;
    const pendingRequisition = last(requisitionData?.requisitiontracks);
    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const handleFileInput = (e) => {
    e.preventDefault();
    let fileInput = e.target;
    setFile(fileInput.files[0]);
  };

  const download = () => {
    const link = document.createElement("a");
    link.setAttribute("download", `${requisition.requestid}.png`);
    link.href = `${backendUrl}/api/requisition/pdf/${
      requisition.requestid
    }?download=true`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <div>
      <Modal
        show={true}
        onHide={() => goBack("/requisition/request")}
        dialogClassName="requisition-details-modal preview-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        fullscreen={styleHelper.isMobile}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{company?.CompName || "..."}</h1>
            <p>{company?.AddressLine1 || "..."}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="preview p-0">
          <div className="preview-info p-4 pt-0 d-flex justify-content-between">
            <p className=" d-flex gap-3 title">
              <span>From:</span>
              <span className="fw-5">
                {requisition
                  ? last(requisition?.requisitiontracks).sentbyUser.Name
                  : "..."}
              </span>
            </p>

            <div className="d-flex action-btns d-print-none">
              <button
                onClick={() =>
                  printJS(
                    `${backendUrl}/api/requisition/pdf/${requisition.requestid}`
                  )
                }
                className="btn"
                title="Print"
              >
                <PrintIcon />
              </button>
              <button
                onClick={() => download()}
                className="btn"
                title="Download"
              >
                <ArrowCollapseDownIcon />
              </button>
            </div>
          </div>
          <div className="preview-content border-bottom">
            <div className="content">
              <div className="preview-header border-bottom mb-3">
                <Logo />

                <span>
                  Date:{" "}
                  {requisition?.regdate
                    ? format(new Date(requisition?.regdate), "eee MMMM do yyyy")
                    : "..."}
                </span>
              </div>
              <div className="preview-body">
                <h2>{requisition?.title}</h2>
                <p>{requisition?.type}</p>

                {!["inventory", "fund request"].includes(
                  lowerCase(requisition?.type)
                ) && <p className="mb-md-3">{requisition?.description}</p>}

                <Table borderless className="form-table">
                  <tbody className="d-md-flex">
                    <tr className="d-flex flex-column w-50">
                      <td>Department:</td>
                      <td>{requisition?.requesttoUser?.Department}</td>
                    </tr>
                    <tr className="d-flex flex-column w-50">
                      <td>Reciepient:</td>
                      <td>{requisition?.requesttoUser?.Name}</td>
                    </tr>
                  </tbody>
                </Table>

                {lowerCase(requisition?.type) === "inventory" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Barcode</th>
                        <th>Item Name</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody ref={ref}>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td
                              className="p-cursor"
                              onClick={(e) => handleRowClick(e, index)}
                            >
                              {el.barcode}
                            </td>
                            <td>{el.itemName}</td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />{" "}
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}
                      <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        onClick={(e) => createNew(e)}
                        className={`p-cursor ${activeTableIndex === -1 &&
                          "active-row"}`}
                      >
                        <td>
                          <span className="">{"..."}</span>
                        </td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody>
                  </Table>
                ) : lowerCase(requisition?.type) === "fund request" ? (
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Unit Cost</th>
                        <th>Quantity</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr
                            style={
                              !canPerformAction(requisition)
                                ? { pointerEvents: "none" }
                                : {}
                            }
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>
                              <input
                                className="form-control border-0 px-1"
                                value={el.description}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: "description",
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.unitCost}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="unitCost"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-1"
                                value={el.quantity}
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                name="quantity"
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                // disableGroupSeparators
                                allowNegativeValue={false}
                                allowDecimals={false}
                              />
                            </td>
                            <td>
                              <CurrencyInput
                                className="form-control border-0 px-0"
                                name="subtotal"
                                disabled
                                value={
                                  !isNaN(el.unitCost * el.quantity)
                                    ? currency(el.unitCost, {
                                        precision: 2,
                                      }).multiply(el.quantity)
                                    : 0
                                }
                                onKeyDown={(e) =>
                                  e.keyCode === 13 && e.target.blur()
                                }
                                onValueChange={(value, name) =>
                                  handleInputChange({
                                    index,
                                    name,
                                    value,
                                  })
                                }
                                //   // disableGroupSeparators
                                decimalsLimit={2}
                              />
                            </td>
                          </tr>
                        ))}

                      <tr
                        style={
                          !canPerformAction(requisition)
                            ? { pointerEvents: "none" }
                            : {}
                        }
                        className={`bg-light p-cursor ${activeTableIndex ===
                          -1 && "active-row"}`}
                        onClick={() =>
                          activeTableIndex !== -1 && setActiveTableIndex(-1)
                        }
                      >
                        <td>
                          <label
                            className=""
                            htmlFor="fund-request-description"
                          >
                            {"..."}
                          </label>
                        </td>
                        <td>
                          <input
                            className="form-control border-0 px-1 bg-white"
                            name="description"
                            id="fund-request-description"
                            value={fundRequest.description}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onChange={(e) =>
                              handleFundRequestInput(
                                e.target.value,
                                e.target.name
                              )
                            }
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.unitCost}
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            name="unitCost"
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            // disableGroupSeparators
                            decimalsLimit={2}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            className="form-control border-0 px-1 bg-white"
                            value={fundRequest.quantity}
                            name="quantity"
                            onKeyDown={(e) =>
                              e.keyCode === 13 && createNewFundRequest(e)
                            }
                            onValueChange={(value, name) =>
                              handleFundRequestInput(value, name)
                            }
                            // disableGroupSeparators
                            allowNegativeValue={false}
                            allowDecimals={false}
                          />
                        </td>
                        <td>
                          {fundRequest.unitCost && fundRequest.quantity ? (
                            <CurrencyInput
                              className="form-control border-0 px-0 bg-white"
                              name="subtotal"
                              disabled
                              value={
                                !isNaN(
                                  fundRequest.unitCost * fundRequest.quantity
                                )
                                  ? currency(fundRequest.unitCost, {
                                      precision: 2,
                                    }).multiply(fundRequest.quantity)
                                  : ""
                              }
                              // // disableGroupSeparators
                              decimalsLimit={2}
                            />
                          ) : null}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
                <Overlay
                  show={show}
                  onHide={() => setShow(false)}
                  target={target}
                  container={document.body}
                  containerPadding={0}
                  rootClose={true}
                  popperConfig={{
                    strategy: "fixed",
                    modifiers: [maxTopPopperConfig({})],
                  }}
                >
                  <Popover
                    style={{
                      maxWidth: "unset",
                      height: "28.75rem",
                      overflow: "hidden",
                      boxShadow: "0px 4px 20px 0px #131B2814",
                    }}
                  >
                    <ItemsTable handleSelectedItem={handleSelectedItem} />
                  </Popover>
                </Overlay>

                {canPerformAction(requisition) &&
                isInventoryOrFundRequest(requisition?.type) ? (
                  <div className="d-flex justify-content-between pb-4 mb-4 border-bottom d-print-none">
                    {![null, -1].includes(activeTableIndex) ? (
                      <Button
                        onClick={() => removeSelection()}
                        variant="danger"
                        size="sm"
                        className="text-white px-3 "
                      >
                        Remove Selection
                      </Button>
                    ) : (
                      <span />
                    )}

                    <div className="proceed-actions">
                      <Button
                        disabled={updateRequisitionMutation.isLoading}
                        onClick={() => save()}
                        variant="outline-primary"
                        size="sm"
                      >
                        {updateRequisitionMutation.isLoading
                          ? "Please wait..."
                          : "Save"}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="preview-footer">
                {isInventoryOrFundRequest(requisition?.type) ? (
                  <>
                    <p>Total Amount Requst Expense: </p>
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">₦</InputGroup.Text>
                      <CurrencyInput
                        placeholder="0.00"
                        className="form-control"
                        //  // disableGroupSeparators
                        disabled
                        value={total}
                      />
                    </InputGroup>
                  </>
                ) : (
                  <hr />
                )}

                {requisition?.requisitiontracks.map((el, index) => (
                  <Fragment key={index}>
                    {!["pending", "disapproved"].includes(
                      lowerCase(el.status)
                    ) ? (
                      <>
                        <Table borderless className="form-table">
                          <tbody className="d-flex">
                            <tr className="d-flex flex-column w-50">
                              <td>Name</td>
                              <td>{el.receivedbyUser?.Name}</td>
                            </tr>
                            <tr className="d-flex flex-column w-50">
                              <td>Date:</td>
                              <td>
                                {format(new Date(el.regdate), "dd - MM - yyyy")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        <Table borderless className="form-table">
                          <tbody className="d-flex">
                            <tr className="d-flex flex-column w-50">
                              <td>{"  "}</td>
                              <td>{el.comment}</td>
                            </tr>
                            <tr className="d-flex flex-column w-50">
                              <td>
                                <label for="signature">Signature :</label>
                              </td>
                              <td>
                                <label className="p-cursor">
                                  <div
                                    className="signature-image"
                                    style={{ height: "12rem" }}
                                  >
                                    {
                                      <img
                                        style={{
                                          width: "12rem",
                                          height: "12rem",
                                        }}
                                        alt=""
                                        src={`${backendUrl}/api/users/signature/${
                                          el.receivedbyUser.Staff_ID
                                        }`}
                                      />
                                    }
                                    {/*file ? (
                                  <img
                                    alt="signature"
                                    src={URL.createObjectURL(file)}
                                  />
                                ) : (
                                  <SignatureIcon />
                                )*/}
                                  </div>

                                  {/*<input
                            id="signature"
                            className="d-none"
                            type="file"
                            name="signature"
                            accept="image/*"
                            onChange={(e) => handleFileInput(e)}
                            /> */}
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </>
                    ) : null}
                  </Fragment>
                ))}
              </div>
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    navigate("/expenses-entry", {
                      state: {
                        expenses: tableData.map((el) => ({
                          ...el,
                          Particulers: el.description,
                          Amount: currency(el?.unitCost, {
                            symbol: "",
                            separator: "",
                          })
                            .multiply(el?.quantity)
                            .format(),
                        })),
                      },
                    })
                  }
                >
                  Expense Entry
                </Button>

                <Button
                  onClick={() =>
                    navigate(
                      `/requisition/request/details/${requisition.requestid}`
                    )
                  }
                >
                  View Progress
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
        {/*canPerformAction() &&
          
            <Modal.Footer>
            <Button
              onClick={() => removeSelection()}
              variant="danger"
              className="text-white px-3"
            >
              Remove Selection
            </Button>

            <div className="proceed-actions">
              <Button
                disabled={updateRequisitionMutation.isLoading}
                onClick={() => goBack("/requisition/request")}
                variant="white"
                className="border bg-white"
              >
                Cancel
              </Button>
              <Button
                disabled={updateRequisitionMutation.isLoading}
                onClick={() => save()}
                variant="primary"
              >
                {updateRequisitionMutation.isLoading
                  ? "Please wait..."
                  : "Save"}
              </Button>
            </div>
                </Modal.Footer> */}
        {canPerformAction(requisition) && (
          <Modal.Footer className="d-print-none variant-2">
            <Button
              onClick={() => reject()}
              variant="danger"
              className="text-white px-3 reject"
            >
              Reject
            </Button>

            <div className="proceed-actions">
              <Link
                to={`/requisition/request/approve-and-send/${id}`}
                state={{ backgroundLocation: location }}
                className="btn btn-white border bg-white"
              >
                Approve & Sent
              </Link>
              <Button
                className="approve-and-close"
                onClick={() => approveAndClose()}
                variant="primary"
              >
                Approve & Close
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}
