import { isEmpty, lowerCase } from "lodash";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import currency from "currency.js";
import { toast } from "react-toastify";
import { customAlphabet } from "nanoid";
import moment, { isMoment } from "moment";

export function activeRowStyle() {
  return {
    backgroundColor: "#2463AE",
    color: "#fff",
  };
}

export function waitFor(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const paginationOptions = {
  previousLabel: <ChevronLeftIcon />,
  nextLabel: <ChevronRightIcon />,
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "page-item",
  previousLinkClassName: "page-link",
  nextClassName: "page-item next",
  nextLinkClassName: "page-link",
  breakLabel: "...",
  breakClassName: "page-item border-none",
  breakLinkClassName: "page-link",
  containerClassName: "pagination",
  activeClassName: "active",
  hrefBuilder: (pageIndex) => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  },
};

export function searchParamsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    if (key === "switchCompany") continue;
    if (!value) continue;
    result[key] = value;
  }

  return result;
}

export function isInventoryOrFundRequest(requisitionType) {
  return ["inventory", "fund request"].includes(lowerCase(requisitionType));
}

export function currencyJs(args) {
  return currency(...args);
}

export function resolveApprovalBadgeBg(status) {
  return lowerCase(status) === "pending"
    ? "warning"
    : lowerCase(status) === "disapproved"
    ? "danger"
    : "success";
}

export const maxTopPopperConfig = ({ maxTop = -210 }) => {
  return {
    name: "maxTopPopperConfig",
    enabled: true,
    phase: "beforeWrite",
    fn({ state }) {
      const oldTransfrom = String(state.styles.popper?.transform);
      const y = Number(
        String(oldTransfrom)
          .split(",")[1]
          .split(")")[0]
          .split("px")[0]
          .trim()
      );

      const x = Number(
        String(oldTransfrom)
          .split(",")[0]
          .split("(")[1]
          .split("px")[0]
          .trim()
      );

      state.styles.popper.transform =
        y < maxTop
          ? `translate(${x}px, ${maxTop}px)`
          : `translate(${x}px, ${y}px)`;
    },
  };
};

export function customerFullName(customer) {
  return `${customer?.FirstName || ""} ${customer?.MiddleName ||
    ""} ${customer?.LastName || ""}`;
}

export function employeeFullName(employee) {
  return `${employee?.FirstName} ${employee?.LastName} ${employee?.MiddleName}`;
}

export function defaultSelectValue(
  value,
  options = [],
  defaultOption = { value: "", label: "All" }
) {
  return value && options
    ? options.find((el) => el.value === value)
    : defaultOption;
}

export function copyText(text) {
  const textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);
  toast.success("Copied");
}

export const styleHelper = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  isMobile: window.matchMedia("(max-width: 960px )").matches,
};

export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    //  primary25: "#E2E8F0",
    primary: "#2463AE",
  },
});

export const qtyFormatToString = (qty) => {
  // console.log(qty);
  const value = String(qty);
  const splitValue = value.split("-");

  const plural = (value) => {
    return Number(value) > 1 ? "s" : "";
  };

  if (splitValue?.length > 1) {
    const tons = splitValue[0];
    const pieces = splitValue[1];
    const splitType = splitValue[2];

    const tonsText = Number(tons) > 0 ? `${tons} ton${plural(tons)}` : "";
    const piecesText =
      Number(pieces) > 0
        ? `${pieces} ${splitType === "normal" ? "" : "pieces"}`
        : `${splitType === "normal" ? "0" : ""}`;

    return `${tonsText} ${piecesText}`;
  } else {
    const tons = splitValue[0];
    return `${tons} ton${plural(tons)}`;
  }
};

// qty to tons and pcs eg 1.5 1 ton 5 pcs
export const qtyFormat = (qty, measurement, itemMeasurements = []) => {
  try {
    // console.log(qty, isEmpty(measurement));
    if (isEmpty(measurement)) return `0-${qty}-normal`;

    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    if (isEmpty(foundMeasurement)) return `0-${qty}-normal`;

    let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
        base = 13;
      // code block
    } */

    const value = currency(qty, { symbol: "", separator: "" })
      .divide(base)
      .format();

    // console.log(value);

    const tons = Math.trunc(Number(value));
    const pcs = qty % base;
    //  console.log(qty, base, tons, pcs);
    return `${tons}-${pcs}`;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const tonsToPcs = (tons, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    let base = foundMeasurement?.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
      // code block
    } */

    const value = currency(tons, { symbol: "", separator: "" })
      .multiply(base)
      .format();
    return Number(value);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const pcsToTons = (pcs, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );
    let base = foundMeasurement.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    const value = currency(pcs, { symbol: "", separator: "" })
      .divide(base)
      .format();

    return parseInt(Number(value));
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const toTonsOrPcs = (qty, measurement, itemMeasurements = []) => {
  const foundMeasurement = itemMeasurements.find(
    (el) => el.Size === measurement
  );
  let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

  const value = currency(qty, { symbol: "", separator: "" })
    .divide(base)
    .format();

  const tons = Math.trunc(Number(value));
  const pcs = qty % base;
  //  console.log(qty, base, tons, pcs);
  return Number(tons) > 0 ? tons : pcs;
};

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

export const nanoid = () => {
  const nanoid = customAlphabet(alphabet, 12);
  const str = nanoid();
  return str;
};

// export const nanoidNumber = () => {}

export function convertMomentDate(date) {
  return isMoment(date)
    ? date.format("MMM Do, YYYY")
    : moment(date).format("MMM Do, YYYY");
}

export function scrollToElement(
  selector,
  options = { block: "center", inline: "center", scroll: true }
) {
  setTimeout(() => {
    const el = document.querySelector(selector);
    if (el) {
      el.focus();
      if (el.select) el.select();
      if (options.scroll) el.scrollIntoView({ behavior: "smooth", ...options });
    }
  }, 50);
}

export const Units = [
  {
    label: "Tons",
    value: "Tons",
  },
  {
    label: "Pieces",
    value: "Pieces",
  },
  {
    label: "Each",
    value: "Each",
  },
  {
    label: "Bags",
    value: "Bags",
  },
  {
    label: "Litres",
    value: "Litres",
  },
  {
    label: "Centiliter",
    value: "Centiliter",
  },
  {
    label: "Drum",
    value: "Drum",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
  {
    label: "KG",
    value: "kG",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "Roll",
    value: "Roll",
  },
  {
    label: "Packets",
    value: "Packets",
  },
  {
    label: "Cups",
    value: "Cups",
  },
  {
    label: "Rubber",
    value: "Rubber",
  },
  {
    label: "Can",
    value: "Can",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Portion",
    value: "Portion",
  },
  {
    label: "Bottle",
    value: "Bottle",
  },
  {
    label: "Bowl",
    value: "Bowl",
  },
  {
    label: "Tin",
    value: "Tin",
  },
];

export const fetchActionsUtil = async (url, method, token, payload) => {
  let response = await fetch(url, {
    method: method,
    // credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      Accept: "Application/json",
      "Content-Type": "Application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    response = await response.json();
    throw new Error(response.message);
  }
  const res = await response.json();
  // console.log(res);
  // const { data, message } = res;
  return res;
};

export const printers = [
  { label: "A4 Size Printer", value: "A4 Size Printer" },
  { label: "Thermal Printer", value: "Thermal Printer" },
];

export const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
  try {
    qty = qty <= 0 ? 0 : qty;
    const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
    const Tons = tonsAndPcs.split("-")[0];
    const Pieces = tonsAndPcs.split("-")[1];
    const formatType = tonsAndPcs.split("-")[2];

    // console.log(tonsAndPcs, desc, qty);

    return {
      Tons,
      Pieces,
      formatType,
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const damageStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Replaced By Vendor",
    value: "Replaced By Vendor",
  },
  {
    label: "Credit Note from Vendor",
    value: "Credit Note from Vendor",
  },
  {
    label: "Quarantined",
    value: "Quarantined",
  },
];
