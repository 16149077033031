import CachedIcon from "mdi-react/CachedIcon";
import { Table, Pagination } from "react-bootstrap";
import {FilterTwoIcon, SummaryChartIconUp, SummaryChartIconDown, SummaryCloseIcon} from "../Icons"
import { CalendarIcon, PrintIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";


function BankAccountAnalysis() {
  const [btnSummaryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true)

  const close = () => {
    setShowMoreSummary(true)
    setBtnSummaryActive(false)
  }
  
  const removeSummaryOnScroll = () => {
     if (document.getElementsByClassName('open')) {
          if(window.scrollY) {
            setBtnSummaryActive(false)
          }
     }       
      
  }

  useEffect(() => {
    removeSummaryOnScroll()
    window.addEventListener("scroll", removeSummaryOnScroll)

    return () =>  window.removeEventListener("scroll", removeSummaryOnScroll)
  }, [])


  return (
    <main className="cash-book">
    <div className="content">
      <header>
        <h1>
          <button className="btn filter">
              <FilterTwoIcon/>
          </button>
          Bank Transactions
          <button className="btn text-primary">
            <CachedIcon />
          </button>
        </h1>
      <div className=" actions mr-5">

    
          <div className="transDropdown form-row d-flex gap-2 align-items-center ">
            <label className="col-form-label">Bank Name</label> 
                    <select name="All Banks" className="form-select ">
                        <option value="transaction">All Bank</option>    
                            
                    </select>
            </div>

          <button onClick={() => setBtnSummaryActive(true) } className="btn summaryBtnWhite ">
            Show Summary
          </button>

        
      </div>
      </header>

      <Table responsive borderless striped className="product-table">
        <thead>
          <tr>
            <th>Trans Date</th>
            <th>Bank Name</th>
            <th>Doc Type</th>
            <th>Customer</th>
            <th>Trans ID</th>
            <th>Payment Type</th>
            <th>Deposits</th>
            <th>Withdrawals</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>01</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>02</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>03</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>04</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>05</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>06</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>07</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>08</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>09</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
          <tr>
            <td>10</td>
            {Array.from({ length: 7 }).map((_, index) => (
              <td key={index}>Table cell {index}</td>
            ))}
          </tr>
        </tbody>
      </Table>

    <div className="pagination ">
          {/* Left */}
              <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select name="rows" className="form-select ">
                          <option value="10">10 rows</option>
                          <option value="20">20 rows</option>
                          <option value="30">30 rows</option>
                          <option value="40">40 rows</option>
                          
                      </select>

              </div>

          {/* right */}
              <div className="pagintion_right">
              <Pagination>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>

                <Pagination.Ellipsis className="border-none" />

                <Pagination.Item>{6}</Pagination.Item>
                <Pagination.Next className="next" />
              </Pagination>
              </div>

    </div>

      {/* <div className="p-4 border-top">
        <h2>Summary</h2>
        <div className="summary">
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Cash Sales</td>
                <td>5,800.00</td>
              </tr>

              <tr>
                <td>Total Cash PMT</td>
                <td>350,250,895.00</td>
              </tr>

              <tr>
                <td>Total Credit/Debit Card/Cheque</td>
                <td>1,204,449,629.06</td>
              </tr>

              <tr>
                <td>Total Cash</td>
                <td>350,256,695.00</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Credit Sales</td>
                <td>1,688,912,055.73</td>
              </tr>

              <tr>
                <td>Total Posted</td>
                <td>3,243,668,379.79</td>
              </tr>
            </tbody>
          </table>
          <table className="table table-borderless balance">
            <tbody>
              <tr>
                <td>Total Expenses</td>
                <td>0.00</td>
              </tr>

              <tr>
                <td>POS to Bank</td>
                <td>103,366,800.00</td>
              </tr>

              <tr>
                <td>Direct Bank Transfer</td>
                <td>1,091,328,596.19</td>
              </tr>

              <tr>
                <td>Cash Sent to Bank</td>
                <td>0.00</td>
              </tr>

              <tr>
                <td>Cash at Hand</td>
                <td>337,732,030.10</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}

      <div className={btnSummaryActive ? `summaryNew border-top open`: `summaryNew ` }  >      

        <div className="gridCont">

            <div className="gridChild gridChildBorderLeftBlue">

                <div className="gridChildLeft">
                  <p className="gridChld1">5,800.00</p>    
                  <p className="gridChld2">Total Cash Sales</p> 
                </div>

                <div className="gridChildRight">
                    <SummaryChartIconUp/>
                    <p>5%</p>
                </div>  
            </div>

            <div className="gridChild gridChildBorderLeftOrange">

                <div className="gridChildLeft">
                  <p className="gridChld1">5,800.00</p>    
                  <p className="gridChld2">Total Cash Sales</p> 
                </div>

                <div className="gridChildRight">
                    <SummaryChartIconUp/>
                    <p>5%</p>
                </div>  
            </div>

            <div className="gridChild gridChildBorderLeftBrown">

                <div className="gridChildLeft">
                  <p className="gridChld1">5,800.00</p>    
                  <p className="gridChld2">Total Cash Sales</p> 
                </div>

                <div className="gridChildRight">
                    <SummaryChartIconDown/>
                    <p>5%</p>
                </div>  
            </div>

      
        </div>

      </div>

     
    </div>
  </main>
  )
}

export default BankAccountAnalysis