import { useState } from "react";
import { NavLink } from "react-router-dom";

export function WarehouseInventoryNav(props) {
  const [items] = useState([
    {
      name: "Inventory List",
      to: "inventory-list",
    },
    {
      name: "Item Issuer",
      to: "item-issuer",
    },
    {
      name: "Branches Inventory",
      to: "branches-inventory",
    },
    {
      name: "Vendor",
      to: "vendor",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
