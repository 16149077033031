import { Outlet } from "react-router-dom";
import PageHeader from "./PageHeader";

export default function InnerPageLayout({ Nav, name, description, icon }) {
  return (
    <>
      <main className="inner-page-outlet">
        {name && <PageHeader {...{ name, description, icon }} />}

        {Nav && <div className="nav-bar">{Nav}</div>}

        <Outlet />
      </main>
    </>
  );
}
