import { Table, Pagination } from "react-bootstrap";
import {  ExportIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import CachedIcon from "mdi-react/CachedIcon";
import DateRangePicker from "../utils/DateRangePicker";

function GeneralLedger() {
  return (
    <main className="cash-book">
    <div className="content">
    <header>
        <h1>
        General Ladger Report
        <button className="btn text-primary">
            <CachedIcon />
        </button>
        </h1>
        <div className="actions mr-5">
        <DateRangePicker />
        <button className="btn print">
            Preview PDF <ExportIcon />
        </button>
        
        </div>
    </header>

    <Table responsive borderless striped className="product-table">
        <thead>
        <tr>
            <th>Date</th>
            <th>Account Type</th>
            <th>GL Account</th>
            <th>Vendor/Client</th>
            <th>Tran Remark</th>
            <th>Debit</th>
            <th>Credit</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>01</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>02</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>03</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>04</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>05</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>06</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>07</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>08</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>09</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        <tr>
            <td>10</td>
            {Array.from({ length: 6 }).map((_, index) => (
            <td key={index}>Table cell {index}</td>
            ))}
        </tr>
        </tbody>
    </Table>

    <div className="pagination">
        {/* Left */}
            <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select name="rows" className="form-select ">
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        
                    </select>

            </div>

        {/* right */}
            <div className="pagintion_right">
            <Pagination>
                <Pagination.Item active>{1}</Pagination.Item>
                <Pagination.Item>{2}</Pagination.Item>

                <Pagination.Ellipsis className="border-none" />

                <Pagination.Item>{6}</Pagination.Item>
                <Pagination.Next className="next" />
            </Pagination>
            </div>

    </div>

    {/* <div className="p-4 border-top">
        <h2>Summary</h2>
        <div className="summary">
        <table className="table table-borderless balance">
            <tbody>
            <tr>
                <td>Total Cash Sales</td>
                <td>5,800.00</td>
            </tr>

            <tr>
                <td>Total Cash PMT</td>
                <td>350,250,895.00</td>
            </tr>

            <tr>
                <td>Total Credit/Debit Card/Cheque</td>
                <td>1,204,449,629.06</td>
            </tr>

            <tr>
                <td>Total Cash</td>
                <td>350,256,695.00</td>
            </tr>
            </tbody>
        </table>
        <table className="table table-borderless balance">
            <tbody>
            <tr>
                <td>Total Credit Sales</td>
                <td>1,688,912,055.73</td>
            </tr>

            <tr>
                <td>Total Posted</td>
                <td>3,243,668,379.79</td>
            </tr>
            </tbody>
        </table>
        <table className="table table-borderless balance">
            <tbody>
            <tr>
                <td>Total Expenses</td>
                <td>0.00</td>
            </tr>

            <tr>
                <td>POS to Bank</td>
                <td>103,366,800.00</td>
            </tr>

            <tr>
                <td>Direct Bank Transfer</td>
                <td>1,091,328,596.19</td>
            </tr>

            <tr>
                <td>Cash Sent to Bank</td>
                <td>0.00</td>
            </tr>

            <tr>
                <td>Cash at Hand</td>
                <td>337,732,030.10</td>
            </tr>
            </tbody>
        </table>
        </div>
    </div> */}

    
    </div>
 </main>
  )
}

export default GeneralLedger

