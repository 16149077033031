import currency from "currency.js";
import { format } from "date-fns";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import { useStoreActions, useStoreState } from "easy-peasy";
import { qtyFormat, qtyFormatToString } from "../../utils/helpers";

export default function PurchaseHistorySelector({
  handleSelectedPurchaseHistory,
}) {
  const { backendUrl, isIronRod } = useAuth();
  const [searchString, setSearchString] = useState("");
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const debouncedSearchString = useDebounce(searchString, 500);

  const fetchPurchaseHistory = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/reports/purchase-history`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, purchaseHistory: [] } } = useQuery(
    [reportActions.PURCHASE_HISTORY],
    () => fetchPurchaseHistory(),
    {
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <h2 className="h5 p-3 pb-1 m-0">Purchase History</h2>
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          placeholder="Search item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content p-2 mb-3">
        <Table
          responsive
          borderless
          striped
          hover
          className="product-table text-nowrap"
        >
          <thead>
            <tr>
              <th>S/N</th>
              <th>Invoice No</th>
              <th>Item Code</th>
              <th>Item Name</th>
              <th>Vendor</th>
              <th>QTY Purchased</th>
              <th>Unit Cost</th>
              <th>Purchase Date</th>
              <th>Username</th>
              <th>Vendor ID</th>
            </tr>
          </thead>
          <tbody>
            {data?.purchaseHistory.map((el, index) => (
              <tr
                key={index}
                className="p-cursor"
                onClick={() => handleSelectedPurchaseHistory({ ...el })}
              >
                <td>{data?.startIndex + index + 1}</td>
                <td>{el.InvoiceNo}</td>
                <td>{el.Bar_Code}</td>
                <td>{el.ItemName}</td>
                <td>{el.Vendor_ID}</td>
                <td>
                  {isIronRod
                    ? qtyFormatToString(
                        qtyFormat(
                          el.Quantity,
                          el.item.Item_Desc,
                          itemMeasurements
                        )
                      )
                    : el.Quantity}
                </td>
                <td>{el.UnitPrice}</td>
                <td>
                  {el.PurchaseDate
                    ? format(new Date(el.PurchaseDate), "dd MMM yyyy")
                    : "..."}
                </td>
                <td>{el.Username}</td>
                <td>{el.Vendor_ID}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
