import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { durationOptions, services } from "../../config";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";

import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import {
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function AddRodItemModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
  saleTypes = [],
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const [unitPriceInTons, setUnitPriceInTons] = useState(0);
  const [unitPriceInPcs, setUnitPriceInPcs] = useState(0);

  const formik = useFormik({
    initialValues: {
      Quantity: 1,
      UnitPrice: "",
      PriceSold: "",
      durationNumber: 1,
      durationValue: "Month",
      Warranty: false,
      Discount: 0,
      ironRodQuantity: 1,
      saleType: "Tons",
      requirePermission: false,
    },
    validationSchema: yup.object().shape({
      ironRodQuantity: yup
        .number()
        .required("required")
        .min(1, "must be greater than 1"),
      UnitPrice: yup.number().required(),
      PriceSold: yup.number().required(),
    }),
    onSubmit: async (values) => {
      console.log(values, selectedItem);

      /* if (values.saleType === "tons") {
        const quantity = tonsToPcs(values.Quantity, selectedItem.Item_Desc);
        if (Number(quantity) > selectedItem?.quantityInStock) {
          return formik.setFieldError(
            "ironRodQuantity",
            `Not enough item in stock`
          );
        }
      } else { */
      if (Number(values.Quantity) > selectedItem?.quantityInStock) {
        return formik.setFieldError(
          "ironRodQuantity",
          `Not enough item in stock`
        );
      }
      // }

      // if ton or pcs
      const theUnitPrice =
        lowerCase(values.saleType) === "tons"
          ? unitPriceInTons
          : unitPriceInPcs;

      if (Number(theUnitPrice) !== Number(values.PriceSold)) {
        if (
          !(await ConfirmDialog({
            description:
              "Selling Price entered will require permission \n Do you wish to continue",
          }))
        ) {
          return;
        } else {
          values.requirePermission = true;
        }
      }

      if (values.Warranty) {
        values.Warrant_Duration = `${values.durationNumber} ${
          values.durationValue
        }`;
      } else {
        values.Warrant_Duration = " ";
      }

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      /* values.defaultSubTotal = currency(values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format(); */

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      // Get Profit Based on batch we are picking from---------------------------------------------------
      const quantityToSell = values.Quantity;
      let remainder = quantityToSell;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );

      if (lowerCase(values.saleType) !== "tons") {
        measurement = {
          Quantity: 1,
        };
      }

      if (lowerCase(values.saleType) === "tons" && !measurement) {
        return toast.error("This item is not sold in Tons");
      }

      const priceSoldPerUnit =
        lowerCase(values.saleType) === "tons"
          ? currency(values.PriceSold)
              .divide(measurement.Quantity)
              .format()
          : values.PriceSold;

      for (let [index, batch] of selectedItem.batches.entries()) {
        const pcsUnitCost = currency(batch.UnitCost, {
          symbol: "",
          separator: "",
        })
          .divide(measurement.Quantity)
          .format();

        if (Number(batch.Quantity) >= Number(remainder)) {
          // means we are at the last
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: remainder,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
          });
          break;
        } else {
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: batch.Quantity,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
          });

          remainder = Number(
            currency(remainder, {
              symbol: "",
              separator: "",
            })
              .subtract(batch.Quantity)
              .format()
          );
        }
      }
      const totalProfit = updatedBatchDataToSave
        .map((el) =>
          currency(el.totalUnitPrice)
            .subtract(el.totalUnitCost)
            .format()
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );
      values.Profit = totalProfit;
      //-------------------------------------------------------------------------------------------------

      /*  values.Profit = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedItem.UnitCost)
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format(); */

      handleAddItem({
        ...selectedItem,
        Serial_Number: selectedItem.Item_Desc,
        ...values,
      });
    },
  });

  const [selectedItem, setselectedItem] = useState(null);
  const [searchString, setSearchString] = useState("");

  const debouncedSearchString = useDebounce(searchString, 500);

  const getItems = async (q) => {
    let response = await fetch(
      `${backendUrl}/api/items?&q=${q}&batch=${true}&batchData=${true}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    [queryActions.GET_ITEMS, debouncedSearchString],
    () => getItems(debouncedSearchString),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSelectedItem = (item) => {
    item.quantityInStock = item.Quantity;
    setselectedItem({ ...item });

    setUnitPriceInTons(item.UnitPrice);
    setUnitPriceInPcs(item.Tax ? item.Tax : item.UnitPrice);

    const UnitPrice = currency(item.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();

    // formik.setFieldValue("Quantity", 1);
    formik.setFieldValue("UnitPrice", UnitPrice);
    formik.setFieldValue("PriceSold", UnitPrice);
    formik.setFieldValue(
      "saleType",
      item?.Product_Model ? item?.Product_Model : "Each"
    );

    setTimeout(() => {
      const el = document.querySelector('input[name="ironRodQuantity"]');
      if (el) {
        el.focus();
        el.select();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  const handleSaleType = (value) => {
    // console.log(value);
    if (lowerCase(value) === "tons") {
      formik.setFieldValue("UnitPrice", unitPriceInTons);
      formik.setFieldValue("PriceSold", unitPriceInTons);
    } else {
      formik.setFieldValue("UnitPrice", unitPriceInPcs);
      formik.setFieldValue("PriceSold", unitPriceInPcs);
    }
  };

  useEffect(() => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.Discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
  }, [formik.values.Discount]);

  // set quantity
  useEffect(() => {
    // if (selectedItem?.Item_Desc) {
    const quantity =
      lowerCase(formik.values.saleType) === "tons" && selectedItem?.Item_Desc
        ? tonsToPcs(
            formik.values.ironRodQuantity,
            selectedItem.Item_Desc,
            itemMeasurements
          )
        : formik.values.ironRodQuantity;
    formik.setFieldValue("Quantity", quantity);
    //}
  }, [
    formik.values.ironRodQuantity,
    formik.values.saleType,
    selectedItem?.Item_Desc,
  ]);

  return (
    <Modal
      show={true}
      onHide={() => setShowItemSelectorModal(false)}
      dialogClassName="item-select-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Item</h1>
          <p>Select item and set the pricing details.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <div className="">
            <h2>Select Item</h2>

            <div className="items-table-area rounded">
              <div className="global-search-area m-3 mx-0">
                <MagnifyIcon />
                <input
                  className="form-control search-input"
                  name="q"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search item..."
                  autoFocus
                  autoComplete="off"
                />
              </div>

              <div className="content">
                <Table borderless striped hover className="product-table">
                  <thead className="sticky">
                    <tr>
                      <th scope="col">Bar_Code</th>
                      <th scope="col">Item_Name</th>
                      {/* <th scope="col">Unit_Cost</th>
                      <th scope="col">Unit_Price</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="blue-hover">
                    {data.items &&
                      data.items.map((el, index) => (
                        <tr
                          className="p-cursor"
                          key={index}
                          onClick={() => handleSelectedItem(el)}
                        >
                          <td>{el.Bar_Code}</td>
                          <td>{el.Item_Name}</td>
                          {/* <td>
                            {currency(el.UnitCost, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.UnitPrice, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td> */}
                          <td>
                            {selectedItem?.Bar_Code === el.Bar_Code ? (
                              <Button variant="primary">Selected</Button>
                            ) : (
                              <Button variant="outline-primary">Select</Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="postion-relative">
            {/*  No item */}
            {!selectedItem && (
              <div className="no-item">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2>No Item Selected</h2>
                  <p>
                    Please select an item from the table beside to fill in the{" "}
                    <br />
                    pricing details.
                  </p>
                </div>
              </div>
            )}
            <h2>Item Details</h2>

            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3 row">
                <Form.Group className="mb-2 col-4">
                  <Form.Label className="slim">Item Name</Form.Label>
                  <p className="selected">
                    {selectedItem?.Item_Name || "..."}{" "}
                  </p>
                </Form.Group>

                <div className="col text-nowrap">
                  <Form.Label className="slim">Item Code</Form.Label>
                  <p className="selected"> {selectedItem?.Bar_Code || "..."}</p>
                </div>

                <div className="col-4">
                  <Form.Label className="slim">Size</Form.Label>
                  <p className="selected">
                    {selectedItem?.Item_Desc || selectedItem?.Product_Model}
                  </p>
                </div>

                <div className="col">
                  <Form.Label className="slim">Quantity in Stock</Form.Label>
                  <p className="selected">
                    {selectedItem?.quantityInStock
                      ? qtyFormatToString(
                          qtyFormat(
                            selectedItem?.quantityInStock,
                            selectedItem.Item_Desc,
                            itemMeasurements
                          )
                        )
                      : "..."}
                  </p>
                </div>

                <div className="col-4">
                  {/*  <Form.Label className="slim">Cost Price</Form.Label>
                  <p className="selected">
                    {currency(selectedItem?.UnitCost, {
                      symbol: "",
                    }).format() || "..."}
                  </p> */}
                </div>
              </Form.Group>
              <hr />

              <h2 className="pt-0">Pricing Details</h2>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Unit Price</Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  value={formik.values.UnitPrice}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onValueChange={(value, name) => {
                    // formik.setFieldValue(name, value)
                  }}
                  placeholder="0.00"
                  decimalsLimit={2}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>
                  Discount <span className="slim">(optional)</span>
                </Form.Label>
                <CurrencyCustomInput
                  name="Discount"
                  value={formik.values.Discount}
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                  placeholder="0.00"
                  decimalsLimit={2}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Final Selling price</Form.Label>
                <CurrencyCustomInput
                  name="PriceSold"
                  placeholder="0.00"
                  decimalsLimit={2}
                  value={formik.values.PriceSold}
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                />
                {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PriceSold}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Quantity</Form.Label>
                <div className="duration">
                  <div>
                    <NumberCustomInput
                      placeholder="0"
                      name="ironRodQuantity"
                      value={formik.values.ironRodQuantity}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value, true);
                      }}
                      isInvalid={
                        formik.touched.ironRodQuantity &&
                        !!formik.errors.ironRodQuantity
                      }
                      onBlur={() =>
                        formik.setFieldTouched("ironRodQuantity", true)
                      }
                      allowNegativeValue={false}
                      allowDecimals={false}
                    />
                    {formik.touched.ironRodQuantity &&
                    !!formik.errors.ironRodQuantity ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.ironRodQuantity}
                      </span>
                    ) : null}
                  </div>

                  <Select
                    classNamePrefix={"form-select"}
                    placeholder={"Select"}
                    isSearchable={false}
                    value={saleTypes.find(
                      (el) => el.value === formik.values.saleType
                    )}
                    options={saleTypes}
                    onChange={({ value }) => {
                      formik.setFieldValue("saleType", value);
                      handleSaleType(value);
                    }}
                  />
                </div>
              </Form.Group>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Enable warranty"
                className="mb-3"
                name="Warranty"
                checked={formik.values.Warranty}
                onChange={formik.handleChange}
              />

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Set Duration</Form.Label>

                <div className="duration">
                  <NumberCustomInput
                    placeholder="0"
                    name="durationNumber"
                    value={formik.values.durationNumber}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                  />

                  <Select
                    classNamePrefix={"form-select"}
                    placeholder={"Month"}
                    value={durationOptions.find(
                      (el) => el.value === formik.values.durationValue
                    )}
                    options={durationOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("durationValue", value)
                    }
                  />
                </div>
              </Form.Group>

              <Button
                disabled={!selectedItem}
                type="submit"
                className="w-100 submit-btn"
              >
                Add to Cart
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
