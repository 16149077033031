import PageHeader from "./PageHeader";
import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
} from "./Icons";
import "./../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import {
  useEffectOnce,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../utils/hooks";
import { services, supplyOptions } from "../config";
import { reportActions } from "../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
} from "../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../hooks/useAuth";
import CurrencyInput from "react-currency-input-field";
import NumberCustomInput from "./utils/NumberCustomInput";
import { toast } from "react-toastify";
import { startOfMonth } from "date-fns";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import { useFormik } from "formik";
import * as yup from "yup";

function UpdateBatchTonsInline({
  getDataForEdit,
  desc,
  itemMeasurements,
  item,
  handleChangeQuantity,
}) {
  const initialValues = {
    Tons: "",
    Pieces: "",
    formatType: "",
  };
  const schema = yup.object().shape({});

  console.log(desc);

  const formik = useFormik({
    initialValues,
    //validationSchema: schema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const tons =
          values?.Tons && values.formatType !== "default"
            ? tonsToPcs(values?.Tons, desc, itemMeasurements)
            : 0;

        let Quantity = Number(
          currency(tons, { symbol: "", separator: "" })
            .add(values.Pieces)
            .format()
        );
        handleChangeQuantity(item, Quantity);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const setUp = () => {
    const { Tons, Pieces, formatType } = getDataForEdit();
    console.log(Tons, Pieces, formatType);

    formik.setValues({
      Tons,
      Pieces,
      formatType,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  return (
    <div
      id={item.ID}
      /* noValidate */
      className="d-flex gap-2"
      /*  onSubmit={formik.handleSubmit} */
    >
      {formik.values.formatType !== "normal" ? (
        <>
          <Form.Group className="mb-3">
            <Form.Label>Tons</Form.Label>
            <NumberCustomInput
              type="text"
              placeholder=""
              name="Tons"
              id={`${item.ID}-Tons`}
              value={formik.values.Tons}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
              style={{ width: "5rem" }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Pieces</Form.Label>
            <NumberCustomInput
              type="text"
              placeholder=""
              name="Pieces"
              id={`${item.ID}-Pieces`}
              value={formik.values.Pieces}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
              style={{ width: "5rem" }}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Pieces}
            </Form.Control.Feedback>
          </Form.Group>
        </>
      ) : (
        <Form.Group className="mb-3">
          <Form.Label>Quantity</Form.Label>
          <NumberCustomInput
            type="text"
            placeholder=""
            name="Pieces"
            value={formik.values.Pieces}
            onValueChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            onKeyDown={(e) => e.keyCode === 13 && formik.submitForm()}
            isInvalid={formik.touched.Pieces && !!formik.errors.Pieces}
            style={{ width: "5rem" }}
          />
          <Form.Control.Feedback type="invalid">
            {formik.errors.Pieces}
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </div>
  );
}

export default function SupplyCenter() {
  useScrollTop();
  const sortBy = [
    {
      value: "Bar_Code",
      label: "Item ID",
    },
    {
      value: "Item_Name",
      label: "Item Name",
    },
    {
      value: "Product_Name",
      label: "Product",
    },

    {
      value: "Cat_Name",
      label: "Category",
    },
  ];
  const initialFilterParams = {
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    status: "Pending Delivery",
  };
  const queryClient = useQueryClient();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);

  const [queryParams, setQueryParams] = useQueryParams({
    /*  page: 1,
    limit: 40, */
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const fetchSupply = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items/supply?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const { data = { count: 0, supply: [] }, refetch } = useQuery(
    ["SUPPLY", queryParams],
    () => fetchSupply(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const updateSupply = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/sale-update`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updateSupplyMutation = useMutation((payload) => updateSupply(payload), {
    onSuccess: ({ updatedItem }) => {
      queryClient.setQueryData(["SUPPLY", queryParams], (data) => {
        const index = data.supply.findIndex(
          (el) => el.TransactionID === updatedItem.TransactionID
        );
        data.supply[index].items = data.supply[index].items.map((el) =>
          el.ID === updatedItem.ID ? { ...updatedItem } : { ...el }
        );
        return data;
      });
      toast.success(`Success`);
    },
    onError: ({ message }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleChangeShippingStatus = async (sale, Shipping_Status) => {
    updateSupplyMutation.mutate({
      editedSale: sale,
      Shipping_Status,
    });
  };

  const handleChangeQuantity = async (sale, Quantity) => {
    Quantity = currency(Quantity, { symbol: "", separator: "" }).format();

    if (Number(Quantity) <= 0) return toast.error("Please enter a number");

    const balanceLeftToShip = currency(sale.QTY, {
      symbol: "",
      separator: "",
      precision: 1,
    })
      .subtract(sale.QTY_Shipped)
      .format();

    if (Number(Quantity) > Number(balanceLeftToShip)) {
      /*  const readableBalance = isIronRod
        ? qtyFormatToString(
            qtyFormat(
              Number(balanceLeftToShip),
              sale.Serial_Number,
              itemMeasurements
            )
          )
        : balanceLeftToShip; */
      return toast.error("Quantity cannot be higher than balance");

      /*  return toast.error(`You have ${balanceLeftToShip} left to Ship`); */
    }
    updateSupplyMutation.mutate({
      editedSale: sale,
      Quantity,
    });
  };

  const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
    try {
      qty = qty <= 0 ? 0 : qty;
      const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
      const Tons = tonsAndPcs.split("-")[0];
      const Pieces = tonsAndPcs.split("-")[1];
      const formatType = tonsAndPcs.split("-")[2];

      // console.log(tonsAndPcs, desc, qty);

      return {
        Tons,
        Pieces,
        formatType,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  return (
    <main className="inner-page-outlet">
      <PageHeader
        name="Supply Center"
        description=""
        icon={<ChartPieSliceTitleIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Supply Center
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5 ">
                  <Select
                    menuPosition="fixed"
                    menuPlacement="auto"
                    placeholder={"Sort by"}
                    classNamePrefix={"form-select"}
                    name="Shipping_Status"
                    defaultValue={
                      supplyOptions.find(
                        (el) => el.value === queryParams?.status
                      ) || {
                        value: "Pending Delivery",
                        label: "Pending Delivery",
                      }
                    }
                    options={supplyOptions}
                    isSearchable={false}
                    onChange={({ value }) =>
                      setQueryParams({
                        ...queryParams,
                        status: value,
                      })
                    }
                  />
                  <RsDateRangePicker
                    placement="bottomEnd"
                    defaultValue={
                      queryParams.startDate && queryParams.endDate
                        ? [
                            parse(
                              queryParams.startDate,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                            parse(
                              queryParams.endDate,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                          ]
                        : []
                    }
                    onClean={() => clearDateRange()}
                    onOk={(date) => filterByDateRange(date)}
                  />
                </div>
              </header>

              {data.supply.map((el, index) => (
                <div key={el.TransactionID} className="px-4 mb-5">
                  <div>
                    <h2 className="h5">{customerFullName(el.customer)}</h2>{" "}
                  </div>
                  <Table
                    responsive
                    borderless
                    striped
                    hover
                    className="product-table text-nowrap mb-3"
                  >
                    <thead>
                      <tr>
                        <th>Item Name / Size</th>
                        <th>Quantity</th>
                        <th>Date</th>
                        <th>TransactionID</th>
                        <th>Status</th>
                        <th>Quantity Supplied</th>
                        <th>Balance</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {el?.items?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.Bar_Code} {item.Item_Name} /{" "}
                            {item.Serial_Number || item?.Product_Model}
                          </td>
                          <td title={item.QTY}>
                            {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    item.QTY,
                                    item.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : item.QTY}
                          </td>
                          <td className="text-nowrap">
                            {item.Date_Log
                              ? format(new Date(item.Date_Log), "dd MMM yyyy")
                              : "..."}
                          </td>
                          <td>{item.TransactionID}</td>
                          <td>
                            <Select
                              key={item?.Shipping_Status}
                              menuPosition="fixed"
                              menuPlacement="auto"
                              classNamePrefix={"form-select"}
                              options={supplyOptions}
                              defaultValue={{
                                label: item?.Shipping_Status || "Not Supplied",
                                value: item?.Shipping_Status || "Not Supplied",
                              }}
                              isSearchable={false}
                              onChange={({ value }) =>
                                handleChangeShippingStatus(item, value)
                              }
                            />
                          </td>
                          <td title={item?.QTY_Shipped}>
                            {isIronRod && item?.QTY_Shipped
                              ? qtyFormatToString(
                                  qtyFormat(
                                    item?.QTY_Shipped,
                                    item.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : item?.QTY_Shipped}
                          </td>
                          <td>
                            {/* {isIronRod
                              ? qtyFormatToString(
                                  qtyFormat(
                                    item.QTY,
                                    item.Serial_Number,
                                    itemMeasurements
                                  )
                                )
                              : item.QTY} */}
                            {/*  <NumberCustomInput
                              defaultValue={currency(item?.QTY, {
                                symbol: "",
                                precision: 0,
                              })
                                .subtract(item?.QTY_Shipped)
                                .format()}
                              className={"bg-white"}
                              style={{ width: "5rem" }}
                              onKeyDown={(e) =>
                                e.keyCode === 13 &&
                                handleChangeQuantity(item, e.target.value)
                              }
                              allowNegativeValue={false}
                              allowDecimals={false}
                            /> */}
                            <UpdateBatchTonsInline
                              key={item?.QTY_Shipped}
                              getDataForEdit={() =>
                                getDataForEdit({
                                  qty: currency(item?.QTY, {
                                    symbol: "",
                                    separator: "",
                                    precision: 0,
                                  })
                                    .subtract(item?.QTY_Shipped)
                                    .format(),
                                  desc: item?.Serial_Number,
                                  itemMeasurements,
                                })
                              }
                              item={item}
                              desc={item?.Serial_Number}
                              itemMeasurements={itemMeasurements}
                              handleChangeQuantity={handleChangeQuantity}
                            />
                          </td>
                          <td />
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-end total-info mt-0 px-4">
                    <div>
                      <table className="table table-borderless balance">
                        <tbody>
                          <tr>
                            <td>Total Debit</td>
                            <td>
                              {currency(el.debit, {
                                symbol: "₦",
                              }).format()}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Credit</td>
                            <td>
                              {currency(el.credit, {
                                symbol: "₦",
                              }).format()}
                            </td>
                          </tr>

                          <tr>
                            <td>Total Balance</td>
                            <td>
                              {currency(el?.balance, {
                                symbol: "₦",
                              }).format()}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}

              {/*    <div className="summaryNew ">
                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Debit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                  <div className="gridChild gridChildBorderLeftDarkGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Credit</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalValueOfItemInStock, {
                          symbol: "",
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </main>

      <ModalLoader show={updateSupplyMutation.isLoading} />
    </main>
  );
}
