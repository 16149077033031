import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import { useQueryParams, useScrollTop } from "../../utils/hooks";
import { ChartPieSliceTitleIcon, DeleteIcon, EditIcon } from "../Icons";
import PageHeader from "../PageHeader";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import AddIncentiveModal from "./AddIncentiveModal";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions, scrollToTop } from "../../utils/helpers";
import { format, parse, startOfMonth } from "date-fns";
import { useQuery, useQueryClient } from "react-query";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";

export default function Incentive() {
  const initialFilterParams = {
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  useScrollTop();
  const [showCreateIncentiveModal, setShowCreateIncentiveModal] = useState(
    false
  );

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const fetchIncentives = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/incentive?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { count: 0, incentives: [] }, refetch } = useQuery(
    ["GET_INCENTIVES", queryParams],
    () => fetchIncentives(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const deleteIncentive = (incentive) => {
    console.log(incentive);
    queryClient.setQueryData(["GET_INCENTIVES", queryParams], (data) => {
      data.incentives = data.incentives.filter(
        (el) => el.IncentiveID !== incentive.IncentiveID
      );

      return { ...data };
    });
  };

  const editIncentive = (incentive) => {};

  return (
    <>
      <PageHeader
        name="Incentives"
        description=""
        icon={<ChartPieSliceTitleIcon />}
      />

      <main className="cash-book">
        <div className="content">
          <header>
            <div className="actions mr-5 gap-3">
              <div
                className="global-search-area w-50"
                style={{ height: "2.5rem", minWidth: "25vw" }}
              >
                <MagnifyIcon />
                <input
                  className="form-control search-input"
                  name="q"
                  placeholder="Search..."
                  autoFocus
                  autoComplete="off"
                  value={filterParams?.q}
                  onChange={(e) => handleFilterParamsChange(e)}
                />
              </div>
              <RsDateRangePicker
                placement="bottomEnd"
                defaultValue={
                  queryParams.startDate && queryParams.endDate
                    ? [
                        parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                        parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                      ]
                    : []
                }
                onClean={() => clearDateRange()}
                onOk={(date) => filterByDateRange(date)}
              />
              <button onClick={() => refetch()} className="btn text-primary">
                <CachedIcon />
              </button>
            </div>

            <Button
              onClick={() => setShowCreateIncentiveModal(true)}
              className="text-nowrap"
            >
              New Incentive
            </Button>
          </header>

          <div className="content-main">
            <div className="content-body">
              <div className="px-4">
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table load-atc text-nowrap"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Score</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {data.incentives.map((el, index) => (
                      <tr key={index}>
                        <td>{el.Name || "..."}</td>
                        <td>{el.score || "..."}</td>
                        <td>
                          <Dropdown style={{ margin: 0 }}>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-light-blue text-primary"
                              bsPrefix="print more"
                            >
                              <DotsHorizontalIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => editIncentive(el)}
                              >
                                <EditIcon /> Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => deleteIncentive(el)}
                              >
                                <DeleteIcon /> Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      {showCreateIncentiveModal && (
        <AddIncentiveModal
          setShowCreateIncentiveModal={setShowCreateIncentiveModal}
        />
      )}
    </>
  );
}
